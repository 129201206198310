import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { availableOptions } from './options/utils';
import * as Components from './options';
import { InitialList } from './options';

export const useSizeUiTracker = (selectOptions, callerPressList) => {
  const [sizeUiTracker, setSizeUiTracker] = useState({ top: '0px', height: '0px' });

  useEffect(() => {
    const itmHeight = 50;
    const anotherHtmlElems = 22;
    const isActiveIndex = callerPressList.findIndex((el) => el.props.isActive);

    const middleOfUI = itmHeight / 2;

    const height =
      (callerPressList.length - isActiveIndex) * itmHeight +
      +anotherHtmlElems -
      (callerPressList.length === selectOptions.length ? itmHeight - 2 : 0) +
      'px';

    const top = isActiveIndex * itmHeight + middleOfUI + 'px';

    setSizeUiTracker({ top, height });
  }, [selectOptions, callerPressList]);
  return sizeUiTracker;
};

const generateInitialList = (func) => ({
  ...availableOptions,
  id: uuid(),
  component: <InitialList handleAddComponent={func} />,
});

export const useCallFlowMethods = () => {
  const [componentList, setComponentList] = useState([]);

  const parseFromServerComponentList = (blocks) => {
    if (!blocks || !blocks.length) return setComponentList([]);

    function findBlockById(id) {
      return blocks.find((b) => b.id === id);
    }

    function processBlock(block) {
      if (!block) return null;

      const {
        Greeting,
        Dial,
        VoiceMail,
        Menu,
        CreateLead,
        Hangup,
        Ai,
        Play,
      } = Components;

      const actionTitleMap = {
        gather: { title: 'gather' },
        respond: { title: 'Menu', component: Menu },
        say: { title: 'Greeting', component: Greeting },
        play: { title: 'Play', component: Play },
        ai: { title: 'AI', component: Ai },
        forward: { title: 'Dial', component: Dial },
        create_lead: { title: 'Create Lead', component: CreateLead },
        record: { title: 'VoiceMail', component: VoiceMail },
        hangup: { title: 'Hangup', component: Hangup },
      };

      const action = actionTitleMap[block.action];
      const Component = action.component;

      const transformedBlock = {
        title: action.title,
        isListOptions: false,
        id: block.id,
        action: block.action,
        parameters: JSON.parse(block?.parameters),
        component: <Component parameters={JSON.parse(block?.parameters)} />,
      };

      if (block.action === 'respond') {
        transformedBlock.availableCallerPressOption = {};
        JSON.parse(block.parameters)['options'].forEach((option) => {
          const optionBlock = findBlockById(option.block_id);
          if (optionBlock) {
            const processedOptionBlock = processBlock(optionBlock);
            if (!transformedBlock.availableCallerPressOption[option.digit]) {
              transformedBlock.availableCallerPressOption[option.digit] = [];
            }
            transformedBlock.availableCallerPressOption[option.digit].push(
              processedOptionBlock
            );
            // Remove block from main array
            const index = blocks.findIndex((b) => b.id === optionBlock.id);
            if (index !== -1) {
              blocks.splice(index, 1);
            }
          }
        });

        // 1 to 9 it is available caller press option in Menu component
        for (let i = 1; i <= 9; i++) {
          if (!transformedBlock.availableCallerPressOption[i]) {
            transformedBlock.availableCallerPressOption[i] = [
              generateInitialList(handleAddComponent),
            ];
          } else {
            const lastElement =
              transformedBlock.availableCallerPressOption[i][
                transformedBlock.availableCallerPressOption[i].length - 1
              ];
            if (
              lastElement &&
              (lastElement.action === 'respond' ||
                lastElement.action === 'ai' ||
                lastElement.action === 'hangup')
            ) {
              // Do nothing, as per your requirement
            } else {
              transformedBlock.availableCallerPressOption[i] = [
                ...transformedBlock.availableCallerPressOption[i],
                generateInitialList(handleAddComponent),
              ];
            }
          }
        }
      }

      return transformedBlock;
    }

    let data = blocks.filter((el) => el.action !== 'gather').map(processBlock);

    let firstMenuIndex = data.findIndex((el) => el.action === 'respond');

    if (firstMenuIndex >= 0) {
      data.length = firstMenuIndex + 1;
    }
    setComponentList(data);
  };

  const blocks = [];

  const createApiBlocks = (arr) => {
    const defineNeededParameters = (item) => {
      let parameters = '';

      if (item.action === 'respond') {
        let options = [];
        for (let i in item.availableCallerPressOption) {
          if (item.availableCallerPressOption[i].length) {
            for (let el of item.availableCallerPressOption[i]) {
              if (el.action !== 'initialList') {
                options.push({ digit: i, block_id: el.id });
              }
            }
          }
        }
        parameters = { options };
      } else if (
        item.action === 'create_lead' ||
        item.action === 'record' ||
        item.action === 'say' ||
        item.action === 'play' ||
        item.action === 'ai' ||
        item.action === 'forward' ||
        item.action === 'gather' ||
        item.action === 'record' ||
        item.action === 'hangup'
      ) {
        let params =
          item.parameters && Object.keys(item.parameters).length > 0
            ? item?.parameters
            : { name: 'default ' + item.action };
        parameters = { ...params };
      }
      return { parameters };
    };
    arr
      .filter((el) => el.action !== 'initialList')
      .forEach((item) => {
        if (item.action === 'respond') {
          //need for back-end
          blocks.push({
            id: item.id + 'gather',
            action: 'gather',
            parameters: { name: 'gather' },
          });
        }
        blocks.push({
          id: item.id,
          action: item.action,
          ...defineNeededParameters(item),
        });
        if (item.action === 'respond') {
          for (let i in item.availableCallerPressOption) {
            if (item.availableCallerPressOption[i].length) {
              createApiBlocks(item.availableCallerPressOption[i]);
            }
          }
        }
      });
    return JSON.stringify(blocks);
  };

  const chains = [];

  const createApiChains = (list) => {
    list
      .filter((el) => el.action !== 'initialList')
      .forEach((item, i) => {
        if (list[i + 1]) {
          chains.push({
            block_id: item.id,
            next_block_id: list[i + 1].id,
          });
        }
      });
    return JSON.stringify([...new Set(chains)]);
  };

  const generateDefaultObj = (obj) => {
    return obj.action === 'respond'
      ? {
          ...obj,
          id: uuid(),
          availableCallerPressOption: {
            1: [generateInitialList(handleAddComponent)],
            2: [generateInitialList(handleAddComponent)],
            3: [generateInitialList(handleAddComponent)],
            4: [generateInitialList(handleAddComponent)],
            5: [generateInitialList(handleAddComponent)],
            6: [generateInitialList(handleAddComponent)],
            7: [generateInitialList(handleAddComponent)],
            8: [generateInitialList(handleAddComponent)],
            9: [generateInitialList(handleAddComponent)],
          },
        }
      : { ...obj, id: uuid() };
  };

  const handleAddComponent = (obj) => {
    setComponentList((prevList) => {
      const clonedList = [...prevList];
      const addToMostNestedBuilder = (list, item) => {
        if (!list.length) {
          list.push(item);
          return list;
        }

        const lastMenu = list.filter((el) => el.action === 'respond')[0];

        if (!lastMenu) {
          list.push(item);

          if (item.action === 'hangup' || item.action === 'ai') {
            handleDeleteAllComponentsAfterHangup(list, item.id);
          }
          return list;
        } else {
          const activeOption = lastMenu.activeCallerPressOption;
          let branch = lastMenu.availableCallerPressOption[activeOption];

          if (branch.length && branch[branch.length - 1].action === 'respond') {
            return addToMostNestedBuilder(branch, item);
          } else {
            branch.splice(branch.length - 1, 0, item);

            if (item.action === 'hangup' || item.action === 'ai') {
              handleDeleteAllComponentsAfterHangup(branch, item.id);
            }
            if (item.action === 'respond') {
              const lastIsListOptions = branch.filter(
                (el) => el.action === 'initialList'
              )[0];
              handleDeleteComponent(lastIsListOptions);
            }
          }
        }
      };

      const defaultObject = generateDefaultObj(obj);

      addToMostNestedBuilder(clonedList, defaultObject);

      return clonedList;
    });
  };

  const handleDeleteAllComponentsAfterHangup = (list, id) => {
    const helper = (listInner) => {
      listInner.forEach((item, i) => {
        if (item.id === id) {
          const restComponents = listInner.slice(i + 1, listInner.length);
          for (let item of restComponents) {
            handleDeleteComponent(item);
          }
        }
        if (item.action === 'respond') {
          helper(item.availableCallerPressOption[item.activeCallerPressOption]);
        }
      });
    };
    helper(list);
  };

  const handleInsertComponent = (insertAfterId, component) => {
    const componentUpd = generateDefaultObj(component);

    const insertComponent = (insertAfterId, component, arr) => {
      const updatedList = arr.map((item) => {
        if (item.id === insertAfterId) {
          if (item.action === 'respond') {
            const activeOption = item.activeCallerPressOption;
            if (
              item.availableCallerPressOption &&
              Array.isArray(item.availableCallerPressOption[activeOption])
            ) {
              return {
                ...item,
                availableCallerPressOption: {
                  ...item.availableCallerPressOption,
                  [activeOption]: [
                    component,
                    ...item.availableCallerPressOption[activeOption],
                  ],
                },
              };
            } else {
              return {
                ...item,
                availableCallerPressOption: {
                  ...item.availableCallerPressOption,
                  [activeOption]: [component],
                },
              };
            }
          } else {
            return [item, component];
          }
        } else if (item.action === 'respond') {
          const activeOption = item.activeCallerPressOption;
          const activeBranch = item.availableCallerPressOption[activeOption];
          const updatedBranch = insertComponent(
            insertAfterId,
            component,
            activeBranch
          );
          if (updatedBranch !== activeBranch) {
            return {
              ...item,
              availableCallerPressOption: {
                ...item.availableCallerPressOption,
                [activeOption]: updatedBranch,
              },
            };
          }
        }
        return item;
      });

      return updatedList.flat();
    };

    setComponentList((prevComponentList) => {
      let arr = insertComponent(insertAfterId, componentUpd, prevComponentList);
      if (componentUpd.action === 'hangup' || componentUpd.action === 'ai') {
        const index = arr.findIndex((b) => b.id === componentUpd.id);
        if (index !== -1) {
          arr.length = index + 1;
          return arr;
        } else {
          const helper = (item) => {
            if (item.action === 'respond') {
              const menuArr =
                item.availableCallerPressOption[item.activeCallerPressOption];

              if (menuArr.some((el) => el.id === componentUpd.id)) {
                const index = menuArr.findIndex((b) => b.id === componentUpd.id);
                menuArr.length = index + 1;

                const availableCallerPressOption = {
                  [item.activeCallerPressOption]: menuArr,
                };

                return {
                  ...item,
                  ...availableCallerPressOption,
                };
              } else {
                const menuNested = menuArr.filter(
                  (el) => el.action === 'respond'
                )[0];
                helper(menuNested);
              }
            } else return item;
          };

          arr.map(helper);

          return arr;
        }
      }
      return arr;
    });
  };

  const handleDeleteComponent = (el) => {
    if (el.action === 'respond' || el.action === 'hangup' || el.action === 'ai') {
      if (
        !componentList.some((el) => el.action === 'hangup' || el.action === 'ai')
      ) {
        handleAddComponent(generateInitialList(handleAddComponent));
      }
    }
    const deleteComponentById = (id, itemList) => {
      return itemList.reduce((acc, item) => {
        if (item.action === 'respond') {
          if (item.id === id) {
            return acc;
          }

          const updatedOptions = {};
          for (const key in item.availableCallerPressOption) {
            updatedOptions[key] = deleteComponentById(
              id,
              item.availableCallerPressOption[key]
            );
          }
          return [
            ...acc,
            {
              ...item,
              availableCallerPressOption: updatedOptions,
            },
          ];
        } else {
          if (item.id !== id) {
            acc.push(item);
          }
          return acc;
        }
      }, []);
    };

    setComponentList((prevComponentList) =>
      deleteComponentById(el.id, prevComponentList)
    );
  };

  const handleDeleteBranchInBuilder = (builderId, branchNumber) => {
    const deleteBranch = (builderId, branchNumber, prevComponentList) => {
      if (!Array.isArray(prevComponentList)) {
        return prevComponentList;
      }

      return prevComponentList.map((item) => {
        if (item.id === builderId) {
          if (item.availableCallerPressOption) {
            item.availableCallerPressOption[branchNumber] = [
              generateInitialList(handleAddComponent),
            ];
          }
          return item;
        } else if (item.availableCallerPressOption) {
          const updatedOptions = {};
          for (const key in item.availableCallerPressOption) {
            updatedOptions[key] = deleteBranch(
              builderId,
              branchNumber,
              item.availableCallerPressOption[key]
            );
          }
          return {
            ...item,
            availableCallerPressOption: updatedOptions,
          };
        } else {
          return item;
        }
      });
    };
    setComponentList((arr) => deleteBranch(builderId, branchNumber, arr));
  };

  const handleMutateBuilderCallerPressOption = (value, id) => {
    setComponentList((val) => {
      const updateComponentList = (components) => {
        return components.map((component) => {
          if (component.id === id && component.action === 'respond') {
            return {
              ...component,
              activeCallerPressOption: value,
            };
          } else if (
            component.action === 'respond' &&
            component.availableCallerPressOption
          ) {
            const updatedAvailableOptions = {};
            for (const key in component.availableCallerPressOption) {
              updatedAvailableOptions[key] = updateComponentList(
                component.availableCallerPressOption[key]
              );
            }
            return {
              ...component,
              availableCallerPressOption: updatedAvailableOptions,
            };
          }
          return component;
        });
      };

      return updateComponentList(val);
    });
  };

  const handleMutateComponentListByParametersInUI = (params, id) => {
    const helper = (arr) => {
      return arr.map((el) => {
        if (el.action === 'respond') {
          const updatedAvailableOptions = {};
          for (const key in el.availableCallerPressOption) {
            updatedAvailableOptions[key] = helper(
              el.availableCallerPressOption[key]
            );
          }
          return {
            ...el,
            availableCallerPressOption: updatedAvailableOptions,
          };
        }
        if (el.id === id) {
          const updParams = { ...el.parameters, ...params };
          return { ...el, parameters: updParams };
        } else {
          return el;
        }
      });
    };

    setComponentList((list) => helper(list));
  };

  const checkAllComponentsReady = (componentList) => {
    const results = componentList.map((component) => {
      if (component.action === 'respond') {
        return Object.values(component.availableCallerPressOption).every(
          (option) => {
            return checkAllComponentsReady(option);
          }
        );
      }
      if (component.action === 'say') {
        return Boolean(component.parameters?.message && component.parameters?.voice);
      }
      if (component.action === 'play') {
        return true;
      }
      if (component.action === 'ai') {
        return Boolean(
          component.parameters?.agent &&
            component.parameters?.ai_profile_id &&
            component.parameters?.scheduled_task_sequence_id
        );
      }
      if (component.action === 'forward') {
        return Boolean(component.parameters?.number);
      }
      if (component.action === 'create_lead') {
        return Boolean(
          component.parameters?.source_id && component.parameters?.team_id
        );
      }
      if (component.action === 'record') {
        return true;
      }
      if (component.action === 'hangup') {
        return true;
      }
      if (component.action === 'initialList') {
        return true;
      }
    });

    return results.every((r) => r);
  };

  const getUpdatedInputParams = (id) => {
    let block;

    const helper = (arr) => {
      arr.forEach((el) => {
        if (el.action === 'respond') {
          for (const key in el.availableCallerPressOption) {
            helper(el.availableCallerPressOption[key]);
          }
        }
        if (el.id === id) {
          block = el;
        }
      });

      return block;
    };

    return helper(componentList);
  };

  return {
    blocks,
    componentList,
    createApiChains,
    createApiBlocks,
    setComponentList,
    handleAddComponent,
    handleDeleteComponent,
    handleInsertComponent,
    getUpdatedInputParams,
    checkAllComponentsReady,
    handleDeleteBranchInBuilder,
    parseFromServerComponentList,
    handleMutateBuilderCallerPressOption,
    handleMutateComponentListByParametersInUI,
  };
};
