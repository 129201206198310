import { useMutation } from 'react-query';
import webhookServices from './webhookServices';

export const webhookSubscribe = (options) => {
  return useMutation(webhookServices.create, {
    mutationKey: ['id', 'subdomain'],
    ...options,
  });
};

export const webhookCancelSubscription = (options) => {
  return useMutation(webhookServices.delete, {
    mutationKey: ['id', 'subscription_id', 'subdomain'],
    ...options,
  });
};
