import { useState } from 'react';
import {
  Box,
  MenuItem,
  Button,
  FormControl,
  Select,
  InputLabel,
  TextField as MuiTextField,
} from '@mui/material';
import { LeadSourcesIcon, XIcon, CheckIcon } from '../../../../theme/icons';

export const FieldOption = ({
  id,
  mutate,
  isActive,
  itemSelectValue,
  selectOptions,
  setTakenOptionsIndexes,
  takenOptionIndexes,
  handleRemoveOption,
  defineActiveOption,
  handleMutateBuilderCallerPressOption,
}) => {
  const [value, setValue] = useState('');
  const [selectValue, setSelectValue] = useState(itemSelectValue);

  const handleChange = (e) => setValue(e.target.value);
  const handleSelect = (e) => {
    setSelectValue(e.target.value);
    setTakenOptionsIndexes((list) => {
      list.delete(+selectValue);
      list.add(+e.target.value);
      return list;
    });
    mutate(selectValue, e.target.value);

    if (isActive) {
      handleMutateBuilderCallerPressOption(+e.target.value, id);
    }
  };

  const handleClick = (e) => {
    if (!isActive) {
      defineActiveOption(e, selectValue);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        zIndex: '3',
      }}
    >
      <FormControl sx={{ width: '80%' }}>
        <Box
          data-parent
          onClick={(e) => handleClick(e)}
          sx={{
            margin: '0 0 10px 0',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            position: 'relative',
            zIndex: '2',
            background: isActive ? '#b1dcff' : 'initial',
            borderLeft: isActive ? '3px solid steelblue' : '3px solid transparent',
            '&:hover': {
              cursor: 'pointer',
              background: '#b1dcff',
            },
          }}
        >
          <Box sx={{ display: 'flex', width: '20%' }}>
            <InputLabel sx={{ padding: '0 10px' }}>Caller press</InputLabel>
            <Select
              sx={{ width: '100%' }}
              defaultValue={selectValue}
              value={selectValue}
              size="small"
              onChange={handleSelect}
            >
              {selectOptions.map(({ operator, label }) => {
                return (
                  <MenuItem
                    disabled={takenOptionIndexes.has(+label)}
                    key={operator}
                    value={operator}
                  >
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <MuiTextField
            sx={{ m: '0 10px', width: '60%' }}
            onChange={handleChange}
            value={value}
            placeholder="type your text here"
            label="text"
            size="small"
          />
          {isActive || (
            <Button
              sx={{
                p: '0',
                m: '0 15px 0 0',
                width: '15px',
                height: '15px',
                minWidth: '15px',
                minHeight: '15px',
                '& span': {
                  m: '0',
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveOption(selectValue);
              }}
              variant="text"
              endIcon={<XIcon />}
            />
          )}
        </Box>
      </FormControl>
      {!isActive ? (
        <LeadSourcesIcon sx={{ m: '0 0 0 25px' }} />
      ) : (
        <CheckIcon sx={{ m: '0 0 0 25px' }} />
      )}
    </Box>
  );
};
