import React, { useState } from 'react';
import ReportCard from './ReportCard';
import DateRangeSelection from '../common/DateRangePicker/DateRangeSelection';
import { Box, Typography } from '@mui/material';
import { useOrg } from '../../contexts/OrgProvider';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const ReportConversionContainer = () => {
  const reportGroups = {
    'apiBase-Leads': [
      { type: 'lead_generated', name: 'Total Leads Generated' },
      { type: 'lead_won', name: 'Total Marked Won' },
      { type: 'lead_conversion_rate', name: 'Total Conversion Rate' },
      {
        type: 'lead_conversion_rate_by_source',
        name: 'Total Conversion Rate by Source',
      },
      { type: 'lead_conversion_by_source', name: 'Total Conversion by Source' },
      { type: 'lead_by_source', name: 'Leads Created by Source' },
      {
        type: 'lead_conversion_rate_by_team',
        name: 'Total Conversion Rate by Team',
      },
      { type: 'lead_worth', name: 'Leads are Worth' },
      { type: 'win_worth', name: 'Wins are Worth' },
      { type: 'average_conversion_time', name: 'Average Time to Convert' },
      { type: 'lead_lost_by_reason', name: 'Lost Reasons' },
      {
        type: 'lead_conversion_with_texts',
        name: '% of Conversions with Text Message Received',
      },
      {
        type: 'lead_conversion_with_emails',
        name: '% of Conversions with Email Received',
      },
      {
        type: 'lead_conversion_without_texts_emails',
        name: '% of Conversions without Text nor Email Received',
      },
    ],
  };

  const { id: orgId, isMasterAccount } = useOrg();
  const [{ startDate, endDate }, setSelectedDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [cohort, setCohort] = useState(false);

  const createParams = (apiGroup, type) => {
    const params = {
      apiGroup,
      canUse: Boolean(startDate && endDate),
      filters: [
        ['type', type],
        ['start_range_date', startDate],
        ['end_range_date', endDate],
        ['cohort', cohort],
      ],
    };
    if (!isMasterAccount) {
      params.filters.push(['organization_id', orgId]);
    }
    return params;
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          mb: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <Typography fontSize={18} fontWeight={700}>
            Conversion Reports
          </Typography>
          <FormControlLabel
            sx={{
              alignItems: 'center',
              marginRight: '26px',
            }}
            label="Cohort"
            control={<Switch checked={cohort} onChange={() => setCohort(!cohort)} />}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'end',
          }}
        >
          <DateRangeSelection
            setSelectedDateRange={setSelectedDateRange}
            earliestAllowedStartDate="09-12-2021"
          />
        </Box>
      </Box>
      <Box>
        {Object.entries(reportGroups).map(([apiGroup, reportTypes]) => (
          <Box
            key={apiGroup}
            sx={{
              pl: 3,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'start',
              gap: 3,
            }}
          >
            {reportTypes.map((report) => (
              <ReportCard
                key={report.type}
                report={report}
                params={createParams(apiGroup, report.type)}
              />
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ReportConversionContainer;
