import { useMutation, useQueryClient } from 'react-query';
import { mutationKeyFactory } from '../queryClientConfig';
import UserOrganizationConnections from './UserOrganizationConnections';
import {
  userOrganizationConnectionsQueryKeys,
  useUserOrganizationConnectionsList,
} from './queries';
import { usersQueryKeys } from '../users/queries';

export const userOrganizationConnectionsMutationKeys = {
  ...mutationKeyFactory('user-organization-connections'),
};

export const useUserOrganizationConnectionsEdit = () => {
  const queryClient = useQueryClient();
  return useMutation(UserOrganizationConnections.edit, {
    mutationKey: userOrganizationConnectionsMutationKeys.edit(),
    onSuccess: () => {
      queryClient.invalidateQueries(userOrganizationConnectionsQueryKeys.lists());
      queryClient.invalidateQueries(usersQueryKeys.lists());
    },
  });
};

export const useUserOrganizationConnectionsDelete = (userId, options) => {
  const queryClient = useQueryClient();
  return useMutation(UserOrganizationConnections.delete, {
    mutationKey: userOrganizationConnectionsMutationKeys.delete(),
    onSuccess: () => {
      queryClient.invalidateQueries(userOrganizationConnectionsQueryKeys.lists());
      queryClient.invalidateQueries(usersQueryKeys.lists());
    },
    ...options,
  });
};

export const useUserOrganizationConnectionsCreate = (options) => {
  const queryClient = useQueryClient();
  return useMutation(UserOrganizationConnections.create, {
    mutationKey: userOrganizationConnectionsMutationKeys.create(),
    onSuccess: () => {
      queryClient.invalidateQueries(userOrganizationConnectionsQueryKeys.lists());
      queryClient.invalidateQueries(usersQueryKeys.lists());
    },
    ...options,
  });
};
