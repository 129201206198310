import Cookies from 'js-cookie';
import { toJSON } from '../queryClientConfig';

export default class webhookServices {
  static async create(subscribe) {
    return await fetch(
      `${process.env.REACT_APP_NEXHEALTH_WEBHOOK}/v1/subscription`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/vnd.Nexhealth+json;version=2',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(subscribe),
      }
    ).then(toJSON);
  }

  static async delete(unsubscribe) {
    return await fetch(
      `${process.env.REACT_APP_NEXHEALTH_WEBHOOK}/v1/cancel-subscription?subdomain=${unsubscribe.subdomain}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    );
  }
}
