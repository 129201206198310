import { useState, useEffect } from 'react';

import {
  webhookSubscribe,
  webhookCancelSubscription,
} from '../services/webhookServices/mutations';
import {
  FormControlLabel,
  TableContainer,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Checkbox,
  Box,
  Grid,
  Link,
  Chip,
  Card,
  Typography,
  Alert,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { useInstitutionsList } from '../../api/institutions/queries';
import { useOrg } from '../../contexts/OrgProvider';
import Calendars from '../services/calendars/Calendars';
import { useCalendarsList } from '../services/calendars/queries';

export default function NextHealthList() {
  const { mutate: subscribe } = webhookSubscribe();

  const { mutate: unsubscribe } = webhookCancelSubscription();
  const { id: orgId } = useOrg();

  const {
    data: InstitutionsData,
    isloading: isInstitutionameLoading,
    isError: isInstitutionsError,
  } = useInstitutionsList();
  const [subscribeState, setSubscribeState] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [checkboxList, setCheckboxList] = useState([]);
  const [selectedInstitution, setSelectedInstitutions] = useState();
  const [isDeactiveSubscribeOpen, setDeactivesubscribeState] = useState(false);
  const [isNexHealthActive, setNexHealthActive] = useState();
  const [isActiveCalenderids, setActiveCalendarid] = useState();
  const [isCircularProgress, setisCircularProgress] = useState(true);
  const [subscribedInstitution, setSubscribedInstitution] = useState();
  const [isSelected, setIsSelected] = useState();
  const [isChangeCircularProgress, setisChangeCircularProgress] = useState(false);
  const [isInstitutionError, seInstitutionError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { data: calendardata } = useCalendarsList([
    { config_item_name: 'subdomain', organization_id: orgId },
  ]);

  useEffect(() => {
    if (
      calendardata &&
      Array.isArray(calendardata.items) &&
      calendardata.items.length > 0
    ) {
      const firstItemId = calendardata.items[0].id;
      setActiveCalendarid([firstItemId]);
      setNexHealthActive(true);
      setisChangeCircularProgress(false);
      setIsLoading(false);
    }
  }, [calendardata]);

  const handleSubscribe = () => {
    setIsDialogOpen(true);
  };

  const handleActivecancel = () => {
    closeDeactivateDialog();
    setisChangeCircularProgress(true);
    unsubscribe(
      {
        subdomain: subscribedInstitution,
      },
      {
        onSuccess: (data) => {
          if (data.status === 200) {
            const response = Calendars.delete(isActiveCalenderids).then(
              (response) => {
                if (response.status === 204) {
                  setNexHealthActive(false);
                  setSubscribeState(false);
                  setisChangeCircularProgress(false);
                } else {
                  setNexHealthActive(true);
                }
              }
            );
          }
        },
      }
    );
  };

  const closeDialog = () => {
    if (isDialogOpen && !subscribeState) {
      if (selectedInstitution) {
        setisChangeCircularProgress(true);
        subscribe(
          {
            subdomain: selectedInstitution,
            resource_type: 'Appointment',
            event: 'appointment_created',
            active: true,
            organization_id: orgId,
          },
          {
            onSuccess: (data) => {
              setSubscribedInstitution(data.config_items[0].value);
              setActiveCalendarid(data.id);
              setisChangeCircularProgress(false);
              seInstitutionError(false);
            },
          }
        );

        setSubscribeState(true);
        setNexHealthActive(true);
      }
    }
    setIsDialogOpen(false);
  };

  const callSubscribe = () => {
    handleSubscribe();
  };

  useEffect(() => {
    if (InstitutionsData && Array.isArray(InstitutionsData)) {
      const namesAndValues = InstitutionsData.map((item) => ({
        name: item.subdomain,
      }));
      setSubscribedInstitution(namesAndValues[0].name);
      setCheckboxList(namesAndValues);
      setisCircularProgress(false);
      setIsLoading(false);
    }
  }, [InstitutionsData]);

  useEffect(() => {
    if (InstitutionsData?.error) {
      seInstitutionError(true);
      setIsLoading(false);
    }
  }, [InstitutionsData]);

  const handleCheckboxChange = (e) => {
    setSelectedInstitutions(e.target.name);
    if (e.target.checked) {
      !isSelected && setIsSelected(e.target.name);
    } else {
      setIsSelected(null);
    }
  };

  const handleDeactivesubsribe = () => {
    setDeactivesubscribeState(true);
  };

  const closeDeactivateDialog = () => {
    if (isDeactiveSubscribeOpen) {
      setDeactivesubscribeState(false);
      if (!setDeactivesubscribeState) {
        setNexHealthActive(false);
      }
    }
  };

  const closeSubscribeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <SettingsCard
        sx={{
          width: '100%',
        }}
      >
        <Grid container>
          <SettingsCard.Header>NexHealth Integrations</SettingsCard.Header>
          <Grid item xs={3} m={0.5}>
            {isNexHealthActive ? (
              <Chip
                label="Active"
                color="primary"
                style={{
                  backgroundColor: '#009900',
                  borderRadius: '4px',
                  mt: '2',
                  border: '0px solid green',
                }}
              />
            ) : (
              <Chip
                label="Inactive"
                color="primary"
                style={{
                  backgroundColor: '#dce0dd',
                  borderRadius: '4px',
                  mt: '2',
                  border: '0px solid #dce0dd',
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" sx={{ marginTop: 2 }}>
          <Grid item xs={8}>
            {isChangeCircularProgress ? (
              <CircularProgress color="inherit" size={20} />
            ) : null}

            {isLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <>
                {isInstitutionError && !isNexHealthActive ? (
                  <Alert severity="warning" sx={{ mb: 2 }}>
                    Contact LeadSigma support at support@leadsigma.com or (913)
                    243-8377 to enable Nexhealth.
                  </Alert>
                ) : null}

                {!isNexHealthActive ? (
                  <Alert severity="info" sx={{ mb: 2 }}>
                    Activate our Nexhealth integration to experience the seamless
                    magic of turning scheduled appointments into won leads
                    automatically. <br />
                    <br /> With support for leading Practice Management Systems (PMS)
                    like Cloud9, Dolphin, Orthotrac, and more, Nexhealth ensures a
                    synchronized and efficient workflow. <br />
                    <br /> Let our AI digital assistant take the lead in scheduling
                    appointments directly on your calendar.
                  </Alert>
                ) : null}
              </>
            )}

            {isNexHealthActive && !isChangeCircularProgress ? (
              <>
                <Typography variant="subtitle1" sx={{ color: 'black' }}>
                  Institution Name
                </Typography>

                {!subscribedInstitution && isCircularProgress ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Typography variant="body2">{subscribedInstitution}</Typography>
                )}
              </>
            ) : null}
          </Grid>
          <Grid item>
            <Card
              sx={{
                minWidth: 250,
                minHeight: 100,
                display: 'flex',
                ml: 4,
              }}
              style={{ backgroundColor: '#f2f1ed' }}
              elevation={0}
            >
              <Grid item ml={2} mt={2} paddingBottom={2}>
                <Typography variant="body1" sx={{ width: 200, minWidth: '100%' }}>
                  Question about this integration? Check out here
                </Typography>
                <Link
                  target="blank"
                  href="https://sigmaleads.atlassian.net/wiki/spaces/LCS/pages/1377009666/NexHealth+Integration"
                >
                  Support Documentation
                </Link>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        {isNexHealthActive ? (
          <Grid item mt={2}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Card
                sx={{
                  minWidth: 250,
                  minHeight: 100,
                  display: 'flex',
                }}
                style={{ backgroundColor: '#f2f1ed' }}
                elevation={0}
              >
                <Grid item mt={2} paddingBottom={2}>
                  <Typography
                    ml={2}
                    variant="body1"
                    sx={{ width: 200, minWidth: '100%' }}
                  >
                    Deactivating this integration will clear your current settings
                    and disconnect NexHealth.
                  </Typography>

                  <Button
                    color="inherit"
                    sx={{
                      color: '#f55e53',
                      border: '2px solid #f55e53',
                      backgroundColor: '#f2f1ed',
                      boxShadow: 'none',
                      ml: 2,
                      mt: 2,
                    }}
                    onClick={handleDeactivesubsribe}
                  >
                    Deactivate NexHealth
                  </Button>
                </Grid>
              </Card>
            </Box>
          </Grid>
        ) : null}

        {isLoading ? null : isInstitutionError ? null : !isNexHealthActive ? (
          <Button color="inherit" onClick={handleSubscribe}>
            Subscribe
          </Button>
        ) : null}

        <TableContainer sx={{ mb: 2 }}></TableContainer>
      </SettingsCard>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>Institution Name</DialogTitle>
        {!checkboxList ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          checkboxList.map((item, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  value={item.name}
                  checked={item.checked}
                  onChange={handleCheckboxChange}
                  name={item.name}
                  key={item.name}
                  disabled={isSelected ? isSelected !== item.name : false}
                />
              }
              label={item.name}
            />
          ))
        )}
        <DialogActions>
          <Button variant="cancel" onClick={closeSubscribeDialog}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            onClick={() => {
              callSubscribe();
              closeDialog();
            }}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={isDeactiveSubscribeOpen} onClose={closeDeactivateDialog}>
        <DialogTitle>Deactivate NexHealth</DialogTitle>
        <DialogContent>
          Are you sure you want to deactivate the integration?
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={closeDeactivateDialog}>
            Cancel
          </Button>
          <LoadingButton onClick={handleActivecancel}>Confirm</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
