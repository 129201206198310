import React, { useState } from 'react';
import ReportCard from './ReportCard';
import DateRangeSelection from '../common/DateRangePicker/DateRangeSelection';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useOrg } from '../../contexts/OrgProvider';

const ReportOverviewContainer = () => {
  const reportGroups = {
    // 'messagingApiBase-Active Call Notifications': [],
    'apiBase-Active Call Notifications': [
      { type: 'acn_accepted', name: 'Active Call Notifications Accepted' },
      { type: 'acn', name: 'Active Call Notifications' },
      { type: 'acn_accept_rate', name: 'Active Call Notifications Accept Rate' },
    ],
    'apiBase-Calls': [
      { type: 'call_answered', name: 'Calls Answered' },
      { type: 'call_received', name: 'Calls Received' },
      { type: 'call_made', name: 'Calls Made' },
      { type: 'call_answer_rate', name: 'Calls Answer Rate' },
    ],
    'apiBase-Emails': [
      { type: 'email_sent', name: 'Emails Sent' },
      { type: 'email_received', name: 'Emails Received' },
      { type: 'email_engagement_rate', name: 'Email Engagement Rate' },
    ],
    'apiBase-Text Messages': [
      { type: 'text_messages_sent', name: 'Text Messages Sent' },
      { type: 'text_messages_received', name: 'Text Messages Received' },
      {
        type: 'text_messages_engagement_rate',
        name: 'Text Message Engagement Rate',
      },
    ],
    'apiBase-Leads': [
      { type: 'lead_worth', name: 'Leads are Worth' },
      { type: 'win_worth', name: 'Wins are Worth' },
      { type: 'pipeline_value', name: 'Current Pipeline Value' },
      { type: 'lead_generated', name: 'Total Leads Generated' },
      { type: 'lead_generated_by_actor', name: 'Total Leads Generated by Creator' },
      { type: 'lead_won', name: 'Total Marked Won' },
    ],
  };

  const { id: orgId, isMasterAccount } = useOrg();
  const [{ startDate, endDate }, setSelectedDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const createParams = (apiGroup, type) => {
    const params = {
      apiGroup,
      canUse: Boolean(startDate && endDate),
      filters: [
        ['type', type],
        ['start_range_date', startDate],
        ['end_range_date', endDate],
      ],
    };
    if (!isMasterAccount) {
      params.filters.push(['organization_id', orgId]);
    }
    return params;
  };

  return (
    <Box>
      <Grid container alignItems="center">
        <Grid item xs={12} md={2}>
          <Typography fontSize={18} fontWeight={700}>
            Reports Overview
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <DateRangeSelection
            setSelectedDateRange={setSelectedDateRange}
            earliestAllowedStartDate="09-12-2021"
          />
        </Grid>
      </Grid>
      <Box>
        {Object.entries(reportGroups).map(([apiGroup, reportTypes]) => (
          <Box key={apiGroup}>
            <Box sx={{ my: 3 }}>
              {apiGroup.split('-')[1]}
              <Divider />
            </Box>
            <Box
              sx={{
                pl: 3,
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'start',
                gap: 3,
              }}
            >
              {reportTypes.map((report) => (
                <ReportCard
                  key={report.type}
                  report={report}
                  params={createParams(apiGroup, report.type)}
                />
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ReportOverviewContainer;
