import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItemText,
  Typography,
} from '@mui/material';
import { BiChevronLeft } from 'react-icons/bi';

import { styled } from '@mui/material/styles';
import { MdKeyboardBackspace } from 'react-icons/md';
import { Button } from 'react-bootstrap';
import { SettingsCard } from '../../MUI/pages/_settingsPagesComponents/SettingsCard';
import {
  formatPhoneNumber,
  formatTimeDuration,
  formatKey,
} from '../../MUI/services/textFormatting';
import {
  dateToAbbreviatedDateTimeString,
  dateToAbbreviatedDateTimeNoWeekdayString,
} from '../../MUI/services/dateTime';
import { useOrganizationsList } from '../../MUI/services/organizations/queries';
import { useCallRecordingsShow } from '../../MUI/services/callRecordings/queries';
import { useTranscriptionsShow } from '../../MUI/services/transcriptions/queries';
import { useVisitsList } from '../../MUI/services/visits/queries';
import CallsEventHistory from '../../MUI/components/calls/CallsEventHistory';

const CustomDivider = styled('hr')(({ theme }) => ({
  margin: '8px -8px 0px',
  color: 'red',

  [theme.breakpoints.up('md')]: {
    margin: '16px -16px 0px',
    color: 'blue',
  },
}));

const CallTrackingDetail = ({ call }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const {
    data: accountsList,
    isError: isAccountsListError,
  } = useOrganizationsList();

  const recording = useCallRecordingsShow(call?.recording_id);
  const transcription = useTranscriptionsShow(recording?.data?.transcription?.id);
  const { data: visits } = useVisitsList([['session_id', call?.session_id]]);

  const formatSecondsToTime = (seconds) => {
    if (isNaN(seconds) || seconds < 0) {
      return '--:--';
    }

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.round(seconds % 60);

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const isValidUrl = (urlString) => {
    try {
      new URL(urlString);
    } catch (_) {
      return false;
    }

    return true;
  };

  const firstVisit = () => {
    if (visits) {
      return visits.items[visits.items.length - 1];
    }
  };

  const parseQueryParams = (visit) => {
    if (visit && isValidUrl(visit.url)) {
      const url = new URL(visit.url);
      const queryParams = new URLSearchParams(url.search);
      let queryParamsHash = {};
      queryParams.forEach((value, key) => {
        queryParamsHash[key] = value;
      });

      return queryParamsHash;
    }
  };
  let queryParams = parseQueryParams(firstVisit());

  return (
    <Box>
      <Button
        variant="link"
        className="tasks-back"
        onClick={() => {
          history.push('/calls');
        }}
      >
        <BiChevronLeft size={'1.2em'} /> Calls
      </Button>

      {call ? (
        <Box mb={2}>
          <Typography
            variant="h6"
            sx={{
              display: 'inline',
              fontWeight: 'bold',
            }}
          >
            {formatPhoneNumber(call.from)}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              display: 'inline',
              fontWeight: 'normal',
            }}
          >
            {call.cnam ? ` > ${call.cnam}` : ' > Anonymous Caller'}
          </Typography>
        </Box>
      ) : (
        <></>
      )}

      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            width: '75%',
            mr: '2%',
          }}
        >
          <SettingsCard>
            {call ? (
              <>
                <Typography>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `${dateToAbbreviatedDateTimeNoWeekdayString(
                        call.created_at
                      )} &#8226; ${call.duration}`,
                    }}
                  />
                </Typography>
                <CustomDivider />
              </>
            ) : (
              <></>
            )}
            <SettingsCard.Main>
              <List>
                {call ? (
                  <ListItemText>
                    {call?.recording_id != null ? (
                      <CallsEventHistory recording={recording} />
                    ) : (
                      <Alert severity="warning">
                        No recording for this call available.
                      </Alert>
                    )}
                  </ListItemText>
                ) : (
                  <Grid
                    container
                    sx={{ my: 3 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Grid>
                )}
                {recording?.status == 'error' ? (
                  <></>
                ) : (
                  <>
                    <Divider />
                    {transcription?.data ? (
                      transcription.data.conversation.length == 0 ? (
                        <Alert severity="warning">
                          No conversation recognized for this call.
                        </Alert>
                      ) : (
                        transcription.data.conversation.map((item, index) => (
                          <Grid key={index} container sx={{ mt: 2 }}>
                            <Grid item xs={2}>
                              <ListItemText
                                primary={item.speaker == 0 ? 'Agent' : 'Customer'}
                              />
                              <Typography
                                variant="subtitle1"
                                sx={{ fontSize: '12px', fontWeight: 'bold' }}
                              >
                                {formatSecondsToTime(item.start)}
                              </Typography>
                            </Grid>
                            <Grid item xs={10}>
                              <ListItemText>{`${item.content}`}</ListItemText>
                            </Grid>
                          </Grid>
                        ))
                      )
                    ) : transcription?.status == 'error' ? (
                      <Alert severity="error">Error loading conversation.</Alert>
                    ) : (
                      <>
                        {(call && call.recording_id == null) ||
                        (recording?.data &&
                          recording?.data.transcription == null) ? (
                          <Alert severity="warning">
                            No conversation for this call available.
                          </Alert>
                        ) : (
                          <Grid
                            container
                            sx={{ my: 3 }}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CircularProgress />
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                )}
              </List>
            </SettingsCard.Main>
          </SettingsCard>

          <SettingsCard mt={2}>
            <SettingsCard.Header>Web Session</SettingsCard.Header>
            <CustomDivider />
            <SettingsCard.Main sx={{ margin: '0px 0px 50px' }}>
              {call ? (
                <List>
                  {Object.entries(call.visit).map(([key, value]) => (
                    <Grid key={key} container>
                      <Grid item xs={2}>
                        <ListItemText primary={formatKey(key)} />
                      </Grid>
                      <Grid item xs={10} sx={{ wordBreak: 'break-all' }}>
                        <ListItemText>{value || '-'}</ListItemText>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid key="Device" container>
                    <Grid item xs={2}>
                      <ListItemText primary="Device" />
                    </Grid>
                    <Grid item xs={10}>
                      <ListItemText>{call.device || '-'}</ListItemText>
                    </Grid>
                  </Grid>
                  <Grid key="Browser" container>
                    <Grid item xs={2}>
                      <ListItemText primary="Browser" />
                    </Grid>
                    <Grid item xs={10}>
                      <ListItemText>{call.browser || '-'}</ListItemText>
                    </Grid>
                  </Grid>
                </List>
              ) : (
                <Grid
                  container
                  sx={{ my: 3 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Grid>
              )}
            </SettingsCard.Main>

            {visits?.items?.length > 0 ? (
              <>
                <SettingsCard.Header>UTM Variables</SettingsCard.Header>
                <CustomDivider />
                <SettingsCard.Main sx={{ margin: '0px 0px 50px' }}>
                  <List>
                    <Grid key="utm_source" container>
                      <Grid item xs={2}>
                        <ListItemText primary="utm_source" />
                      </Grid>
                      <Grid item xs={10}>
                        <ListItemText>{queryParams.utm_source || '-'}</ListItemText>
                      </Grid>
                    </Grid>
                    <Grid key="utm_medium" container>
                      <Grid item xs={2}>
                        <ListItemText primary="utm_medium" />
                      </Grid>
                      <Grid item xs={10}>
                        <ListItemText>{queryParams.utm_medium || '-'}</ListItemText>
                      </Grid>
                    </Grid>
                    <Grid key="utm_term" container>
                      <Grid item xs={2}>
                        <ListItemText primary="utm_term" />
                      </Grid>
                      <Grid item xs={10}>
                        <ListItemText>{queryParams.utm_term || '-'}</ListItemText>
                      </Grid>
                    </Grid>
                    <Grid key="utm_content" container>
                      <Grid item xs={2}>
                        <ListItemText primary="utm_content" />
                      </Grid>
                      <Grid item xs={10}>
                        <ListItemText>{queryParams.utm_content || '-'}</ListItemText>
                      </Grid>
                    </Grid>
                    <Grid key="utm_campaign" container>
                      <Grid item xs={2}>
                        <ListItemText primary="utm_campaign" />
                      </Grid>
                      <Grid item xs={10}>
                        <ListItemText>
                          {queryParams.utm_campaign || '-'}
                        </ListItemText>
                      </Grid>
                    </Grid>
                  </List>
                </SettingsCard.Main>

                <SettingsCard.Header>Visits</SettingsCard.Header>
                <CustomDivider />
                {visits.items.map((item, index) => (
                  <SettingsCard.Main>
                    <Grid key="date" container>
                      <Grid item xs={2}>
                        <ListItemText primary="Date" />
                      </Grid>
                      <Grid item xs={10}>
                        <ListItemText
                          primary={dateToAbbreviatedDateTimeString(item.created_at)}
                        />
                      </Grid>
                    </Grid>
                    <Grid key="url" container>
                      <Grid item xs={2}>
                        <ListItemText primary="URL" />
                      </Grid>
                      <Grid item xs={10} sx={{ wordBreak: 'break-all' }}>
                        <ListItemText primary={item.url || '-'} />
                      </Grid>
                    </Grid>
                    <Grid key="referring_url" container>
                      <Grid item xs={2}>
                        <ListItemText primary="Referring Url" />
                      </Grid>
                      <Grid item xs={10} sx={{ wordBreak: 'break-all' }}>
                        <ListItemText primary={item.referring_url || '-'} />
                      </Grid>
                    </Grid>
                    {index == visits.items.length - 1 ? '' : <Divider />}
                  </SettingsCard.Main>
                ))}
              </>
            ) : (
              <></>
            )}
          </SettingsCard>
        </Box>

        <Box sx={{ width: '20rem' }}>
          <SettingsCard>
            <SettingsCard.Header>Tracking Details</SettingsCard.Header>
            <CustomDivider />
            <SettingsCard.Main>
              {call ? (
                <List>
                  <Grid container>
                    <Grid item>
                      <ListItemText primary="Account:" />
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        {call.appended_account_name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <ListItemText primary="Tracking Number:" />
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        {call.phone.name && (
                          <>
                            {call.phone.name}
                            <br />
                          </>
                        )}
                        {formatPhoneNumber(call.phone.number)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <ListItemText primary="Start Time:" />
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        {dateToAbbreviatedDateTimeString(call.created_at)}
                      </Typography>
                    </Grid>
                  </Grid>
                </List>
              ) : (
                <Grid
                  container
                  sx={{ my: 3 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Grid>
              )}
            </SettingsCard.Main>
          </SettingsCard>

          <SettingsCard mt={2}>
            {call ? (
              <>
                <SettingsCard.Header>
                  {call.cnam || 'Anonymous Caller'}
                </SettingsCard.Header>

                <CustomDivider />
                <SettingsCard.Main>
                  <Grid container>
                    <Grid item>
                      <ListItemText>{formatPhoneNumber(call.from)}</ListItemText>
                      <ListItemText>{`${call.city || ''}${
                        call.city && call.state ? ', ' : ''
                      }${call.state || ''}`}</ListItemText>
                    </Grid>
                  </Grid>
                </SettingsCard.Main>
              </>
            ) : (
              <Grid
                container
                sx={{ my: 3 }}
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
            )}
          </SettingsCard>
        </Box>
      </Box>
    </Box>
  );
};

export default CallTrackingDetail;
