import React, { useState } from 'react';
import ReportCard from './ReportCard';
import ReportsUsersChart from './ReportsUsersChart';
import DateRangeSelection from '../common/DateRangePicker/DateRangeSelection';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useOrg } from '../../contexts/OrgProvider';

const ReportActivityContainer = () => {
  const reportsByUsersGroups = [
    ['types[]', 'manual_call_made_by_user'],
    ['types[]', 'manual_email_sent_by_user'],
    ['types[]', 'manual_text_messages_sent_by_user'],
    ['types[]', 'task_cancelation_by_user'],
    ['types[]', 'task_completion_by_user'],
    ['types[]', 'lead_won_by_user'],
    ['types[]', 'lead_lost_by_user'],
    ['types[]', 'daily_logins_by_user'],
  ];
  const reportGroups = {
    'Active Call Notifications': {
      apiBase: [
        { type: 'acn_accepted', name: 'Active Call Notifications Accepted' },
        { type: 'acn', name: 'Active Call Notifications' },
        { type: 'acn_accept_rate', name: 'Active Call Notifications Accept Rate' },
      ],
      messagingApiBase: [
        {
          type: 'acn_accept_rate_by_team',
          name: 'Active Call Notifications Accept Rate by Team',
        },
      ],
    },
    'Automated Communication': {
      apiBase: [
        {
          type: 'automated_text_messages_sent',
          name: 'Automated Text Messages Sent',
        },
        { type: 'text_messages_received', name: 'Text Messages Received' },
        { type: 'automated_email_sent', name: 'Automated Emails Sent' },
        { type: 'email_received', name: 'Emails Received' },
      ],
    },
    Calls: {
      apiBase: [
        { type: 'call_answered', name: 'Calls Answered' },
        { type: 'call_received', name: 'Calls Received' },
        { type: 'call_made', name: 'Calls Made' },
        { type: 'call_answer_rate', name: 'Calls Answer Rate' },
      ],
    },
  };

  const { id: orgId, isMasterAccount } = useOrg();
  const [{ startDate, endDate }, setSelectedDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const createParams = (apiGroup, type) => {
    const params = {
      apiGroup,
      canUse: Boolean(startDate && endDate),
      filters: [
        ['type', type],
        ['start_range_date', startDate],
        ['end_range_date', endDate],
      ],
    };
    if (!isMasterAccount) {
      params.filters.push(['organization_id', orgId]);
    }
    return params;
  };
  const createReportsByUsersFilters = () => {
    const params = {
      canUse: Boolean(startDate && endDate),
      filters: [
        ...reportsByUsersGroups,
        ['start_range_date', startDate],
        ['end_range_date', endDate],
      ],
    };
    if (!isMasterAccount) {
      params.filters.push(['organization_id', orgId]);
    }
    return params;
  };

  return (
    <Box>
      <Grid container alignItems="center">
        <Grid item xs={12} md={2}>
          <Typography fontSize={18} fontWeight={700}>
            Activity Reports
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <DateRangeSelection
            setSelectedDateRange={setSelectedDateRange}
            earliestAllowedStartDate="09-12-2021"
          />
        </Grid>
      </Grid>
      <Box>
        <Box sx={{ mt: 3, pl: 3 }}>
          <ReportsUsersChart params={createReportsByUsersFilters()} />
        </Box>
        {Object.entries(reportGroups).map(([reportType, groups]) => (
          <Box key={reportType}>
            <Box sx={{ my: 3 }}>
              {reportType}
              <Divider />
            </Box>
            <Box
              sx={{
                pl: 3,
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'start',
                gap: 3,
              }}
            >
              {Object.entries(groups).map(([apiGroup, reports]) =>
                reports.map((report) => (
                  <ReportCard
                    key={report.type}
                    report={report}
                    params={createParams(apiGroup, report.type)}
                  />
                ))
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ReportActivityContainer;
