const getTeamName = (rule = {}, teams = []) => {
  if (!rule || !teams || !rule.action_data) return '';

  const teamId = rule.action_data.team_id;
  if (!teamId) return '';

  return teams.find((team) => {
    return String(team.id) == String(teamId);
  })?.name;
};

const getAccountName = (rule = {}, accounts = []) => {
  if (!rule || !accounts || !rule.action_data) return '';

  const accountId = rule.action_data.organization_id;
  if (!accountId) return '';

  return accounts.find((account) => {
    return String(account.id) == String(accountId);
  })?.name;
};

const getSequenceName = (rule = {}, task_sequences = []) => {
  if (!rule || !task_sequences || !rule.action_data) return '';

  const taskSequenceId = rule.action_data.task_sequence_id;
  if (!taskSequenceId) return '';

  return task_sequences.find((task_sequence) => {
    return String(task_sequence.id) == String(taskSequenceId);
  })?.name;
};

const getSourceName = (rule = {}, sources = []) => {
  if (!rule || !sources || !rule.action_data) return '';

  const sourceId = rule.action_data.source_id;
  if (!sourceId) return '';

  return sources.find((source) => {
    return String(source.id) == String(sourceId);
  })?.name;
};

const generatePrettyRuleForDisableAcn = (rule, leadFieldName) => {
  return `When <b>${leadFieldName}</b> ${
    operatorsNameMap[rule.operator]?.sentence
  } "<b>${rule.value ?? ''}</b>", disable the Active Call Notification`;
};

const generatePrettyRuleForDisableIngestion = (rule, leadFieldName) => {
  return `When <b>${leadFieldName}</b> ${
    operatorsNameMap[rule.operator]?.sentence
  } "<b>${rule.value ?? ''}</b>", ignore the lead`;
};

const generatePrettyRuleForTeam = (rule, leadFieldName, teamName) => {
  return `When <b>${leadFieldName}</b> ${
    operatorsNameMap[rule.operator]?.sentence
  } "<b>${rule.value ?? ''}</b>", set team to <b>${teamName}</b>`;
};

const generatePrettyRuleForAccount = (rule, leadFieldName, orgName) => {
  return `When <b>${leadFieldName}</b> ${
    operatorsNameMap[rule.operator]?.sentence
  } "<b>${rule.value ?? ''}</b>", set account to <b>${orgName}</b>`;
};

const generatePrettyRuleForSequence = (rule, leadFieldName, sequenceName) => {
  return `When <b>${leadFieldName}</b> ${
    operatorsNameMap[rule.operator]?.sentence
  } "<b>${rule.value ?? ''}</b>", set task sequence to <b>${sequenceName}</b>`;
};

const generatePrettyRuleForSource = (rule, leadFieldName, sourceName) => {
  return `When <b>${leadFieldName}</b> ${
    operatorsNameMap[rule.operator]?.sentence
  } "<b>${rule.value ?? ''}</b>", set source to <b>${sourceName}</b>`;
};

export const getPrettyRule = (
  rule = {},
  teams = {},
  leadFields = {},
  accounts = [],
  sources = [],
  task_sequences = []
) => {
  if (!rule || !rule.slingshot_field || !teams || !leadFields) return '';

  const leadField = leadFields.find((item) => {
    return item.slingshot_field_id == rule.slingshot_field.id;
  });

  if (rule.action == 'disable_slingshot') {
    return generatePrettyRuleForDisableAcn(rule, leadField?.name);
  } else if (rule.action == 'disable_all') {
    return generatePrettyRuleForDisableIngestion(rule, leadField?.name);
  } else if (rule.action == 'set_team') {
    const teamName = getTeamName(rule, teams);
    return generatePrettyRuleForTeam(rule, leadField?.name, teamName);
  } else if (rule.action == 'set_account') {
    const accountName = getAccountName(rule, accounts);
    return generatePrettyRuleForAccount(rule, leadField?.name, accountName);
  } else if (rule.action == 'set_task_sequence') {
    const sequenceName = getSequenceName(rule, task_sequences);
    return generatePrettyRuleForSequence(rule, leadField?.name, sequenceName);
  } else if (rule.action == 'set_source') {
    const sourceName = getSourceName(rule, sources);
    return generatePrettyRuleForSource(rule, leadField?.name, sourceName);
  }
  return '';
};

export const actionsNameMap = {
  disable_all: {
    selector: 'Ignore Lead',
    deleting: 'these leads to flow into your system?',
  },
  disable_slingshot: {
    selector: 'Disable Active Call Notification',
    deleting: 'to have Active Call Notifications for these leads?',
  },
  set_team: {
    selector: 'Set Team',
    deleting: 'these leads to no longer go to this assigned team?',
  },
  set_account: {
    selector: 'Set Account',
    deleting: 'these leads to no longer go to this account?',
  },
  set_task_sequence: {
    selector: 'Set Task Sequence',
    deleting: 'these leads to no longer go to this task sequence?',
  },
  set_source: {
    selector: 'Set Source',
    deleting: 'these leads to no longer go to this source?',
  },
};

export const operatorsNameMap = {
  equal: { selector: 'Equals', sentence: 'is' },
  not_equal: { selector: 'Does not Equal', sentence: 'is not' },
  contains: { selector: 'Contains', sentence: 'contains' },
  starts_with: { selector: 'Starts With', sentence: 'starts with' },
  ends_with: { selector: 'Ends With', sentence: 'ends with' },
};
