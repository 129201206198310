import React from 'react';
import { Box, Typography } from '@mui/material';
import { Container } from './common/Container';
import InsertStep from './options/InsertStep';

export const MappedOptions = ({
  componentList,
  createApiBlocks,
  handleAddComponent,
  doSearchEmptyInputs,
  handleInsertComponent,
  handleDeleteComponent,
  getUpdatedInputParams,
  handleDeleteBranchInBuilder,
  parentActiveCallerPressOption,
  handleMutateBuilderCallerPressOption,
  handleMutateComponentListByParametersInUI,
  ...rest
}) => {
  const renderComponent = (option) => {
    return (
      <Container
        id={option.id}
        component={option}
        handleDeleteComponent={() => handleDeleteComponent(option)}
        isListOptions={option.isListOptions}
        title={option.title}
      >
        <Box>
          <Typography
            textAlign={option.isListOptions ? 'center' : 'left'}
            variant="h5"
            pt={option.isListOptions ? 4 : 0}
            mb={2}
          >
            {option.title}
          </Typography>
          <Box>
            {React.cloneElement(option.component, {
              handleMutateBuilderCallerPressOption:
                option.action === 'respond'
                  ? handleMutateBuilderCallerPressOption
                  : null,
              handleDeleteBranchInBuilder:
                option.action === 'respond' ? handleDeleteBranchInBuilder : null,
              id: option.id,
              doSearchEmptyInputs,
              componentList,
              handleMutateComponentListByParametersInUI,
              getUpdatedInputParams,
              handleAddComponent,
              availableCallerPressOption:
                option.action === 'respond' && option.availableCallerPressOption,
              activeCallerPressOption:
                option?.activeCallerPressOption ||
                parentActiveCallerPressOption ||
                1,
            })}
          </Box>
        </Box>
      </Container>
    );
  };

  const renderChildOptions = (options, parentActiveCallerPressOption) => {
    return options.map((childOption) => (
      <MappedOptions
        key={childOption.id}
        doSearchEmptyInputs={doSearchEmptyInputs}
        componentList={[childOption]}
        handleAddComponent={handleAddComponent}
        handleInsertComponent={handleInsertComponent}
        handleDeleteComponent={handleDeleteComponent}
        getUpdatedInputParams={getUpdatedInputParams}
        handleMutateComponentListByParametersInUI={
          handleMutateComponentListByParametersInUI
        }
        handleDeleteBranchInBuilder={
          childOption.action === 'respond' && handleDeleteBranchInBuilder
        }
        availableCallerPressOption={
          childOption.action === 'respond' && childOption.availableCallerPressOption
        }
        handleMutateBuilderCallerPressOption={handleMutateBuilderCallerPressOption}
        parentActiveCallerPressOption={
          childOption?.activeCallerPressOption || parentActiveCallerPressOption
        }
      />
    ));
  };

  return (
    <>
      {componentList.map((option) => (
        <React.Fragment key={option.id}>
          {renderComponent(option)}
          <InsertStep
            handleInsertComponent={handleInsertComponent}
            insertAfterId={option.id}
          />
          {option.action === 'respond' &&
            option.availableCallerPressOption[option.activeCallerPressOption]
              ?.length > 0 && (
              <>
                {renderChildOptions(
                  option.availableCallerPressOption[option.activeCallerPressOption]
                )}
              </>
            )}
        </React.Fragment>
      ))}
    </>
  );
};
