import { useState, useEffect } from 'react';
import {
  TableContainer,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Grid,
  Link,
  Chip,
  Card,
  Typography,
  Alert,
  CircularProgress,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { useOrg } from '../../contexts/OrgProvider';
import { fireDialog } from '../components/Dialog';
import HookForm from '../components/HookForm';
import { useCalendarsList } from '../services/calendars/queries';
import Calendars from '../services/calendars/Calendars';
import { useCloud9CalendarCreate } from '../services/cloud9Calendars/mutations';
import { useQueryClient } from 'react-query';
import { calendarsQueryKeys } from '../services/calendars/queries';
import Cloud9SettingsPageCalendar from './Cloud9SettingsPageCalendar';

export default function Cloud9SettingsPage() {
  const { id: orgId } = useOrg();
  const { data: calendarData } = useCalendarsList([
    {
      vendor: 'cloud9',
      organization_id: orgId,
    },
  ]);
  const [isDeactiveSubscribeOpen, setDeactivesubscribeState] = useState('');
  const [isCloud9Active, setCloud9Active] = useState(null);
  const [isCloud9Error, setCloud9Error] = useState();
  const [isLoading, setIsLoading] = useState();
  const [calendarId, setActiveCalendarid] = useState(false);
  const [calendarARN, setcalendarARN] = useState(false);

  const closeDeactivateDialog = () => {
    setDeactivesubscribeState(false);
  };

  const handleSubscribe = () => {
    fireDialog((promiseProps) => <Addcloud9settings {...promiseProps} />);
  };

  useEffect(() => {
    if (calendarData?.items && calendarData.items.length > 0) {
      setCloud9Active(true);
      setActiveCalendarid(true);
      setcalendarARN(true);
      setIsLoading(false);
    }
  }, [calendarData]);

  const handleDeactivate = () => {
    Calendars.delete(calendarId).then((response) => {
      if (response.status === 204) {
        setDeactivesubscribeState(false);
        setCloud9Active(false);
      } else {
        setDeactivesubscribeState(false);
      }
    });
  };

  const handleDisableCloud9 = () => {
    setDeactivesubscribeState(true);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <SettingsCard
        sx={{
          width: '100%',
        }}
      >
        <Grid container>
          <SettingsCard.Header>Cloud9 Integration</SettingsCard.Header>
          <Grid item xs={3} m={0.5}>
            {isCloud9Active ? (
              <Chip
                label="Active"
                color="primary"
                style={{
                  backgroundColor: '#009900',
                  borderRadius: '4px',
                  mt: '2',
                  border: '0px solid green',
                }}
              />
            ) : (
              <Chip
                label="Inactive"
                color="primary"
                style={{
                  backgroundColor: '#dce0dd',
                  borderRadius: '4px',
                  mt: '2',
                  border: '0px solid #dce0dd',
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" sx={{ marginTop: 2 }}>
          <Grid item xs={8}>
            {isLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <>
                {isCloud9Error && !isCloud9Active ? (
                  <Alert severity="warning" sx={{ mb: 2 }}>
                    Contact LeadSigma support at support@leadsigma.com or (913)
                    243-8377 to enable Cloud9.
                  </Alert>
                ) : null}

                {!isCloud9Active && !isLoading ? (
                  <Alert severity="info" sx={{ mb: 2 }}>
                    Activate our Cloud9 integration to experience the seamless magic
                    of turning scheduled appointments into won leads automatically.
                    <br /> Let our AI digital assistant take the lead in scheduling
                    appointments directly on your calendar.
                  </Alert>
                ) : null}
              </>
            )}
          </Grid>
          {calendarARN && isCloud9Active && !isLoading ? (
            <Grid item xs={8}>
              <Cloud9SettingsPageCalendar calendarData={calendarData} />
            </Grid>
          ) : null}
          <Grid item>
            <Card
              sx={{
                minWidth: 250,
                minHeight: 100,
                display: 'flex',
                ml: 4,
              }}
              style={{ backgroundColor: '#f2f1ed' }}
              elevation={0}
            >
              <Grid item ml={2} mt={2} paddingBottom={2}>
                <Typography variant="body1" sx={{ width: 200, minWidth: '100%' }}>
                  Question about this integration? Check out here
                </Typography>
                <Link
                  target="blank"
                  href="https://sigmaleads.atlassian.net/wiki/spaces/LCS/pages/1453260801/Cloud9"
                >
                  Support Documentation
                </Link>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        {isCloud9Active ? (
          <Grid item mt={2}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Card
                sx={{
                  minWidth: 250,
                  minHeight: 100,
                  display: 'flex',
                }}
                style={{ backgroundColor: '#f2f1ed' }}
                elevation={0}
              >
                <Grid item mt={2} paddingBottom={2}>
                  <Typography
                    ml={2}
                    variant="body1"
                    sx={{ width: 200, minWidth: '100%' }}
                  >
                    Deactivating this integration will clear your current settings
                    and disconnect Cloud9.
                  </Typography>
                  <Button
                    color="inherit"
                    sx={{
                      color: '#f55e53',
                      border: '2px solid #f55e53',
                      backgroundColor: '#f2f1ed',
                      boxShadow: 'none',
                      ml: 2,
                      mt: 2,
                    }}
                    onClick={handleDisableCloud9}
                  >
                    Deactivate Cloud9
                  </Button>
                </Grid>
              </Card>
            </Box>
          </Grid>
        ) : null}
        {!isCloud9Active ? (
          <Button color="inherit" onClick={handleSubscribe}>
            Enable
          </Button>
        ) : null}

        <TableContainer sx={{ mb: 2 }}></TableContainer>
      </SettingsCard>

      <Dialog open={isDeactiveSubscribeOpen} onClose={closeDeactivateDialog}>
        <DialogTitle>Deactivate Cloud9</DialogTitle>
        <DialogContent>
          Are you sure you want to deactivate the integration?
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={closeDeactivateDialog}>
            Cancel
          </Button>
          <LoadingButton onClick={handleDeactivate}>Confirm</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const Addcloud9settings = ({ isOpen, onReject, onResolve }) => {
  const { id: orgId } = useOrg();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [url, setUrl] = useState('');
  const [clientId, setClientId] = useState('');
  const {
    mutate: cloud9subscribe,
    isError,
    isLoading,
    isSuccess,
  } = useCloud9CalendarCreate();
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [isErrorVisible, setErrorVisible] = useState(false);
  const queryClient = useQueryClient();

  const handleCreateSubmit = () => {
    cloud9subscribe(
      {
        cloud9_username: username,
        cloud9_password: password,
        cloud9_service_url: url,
        cloud9_client_id: clientId,
        organization_id: orgId,
      },
      {
        onSuccess: () => {
          setAlertVisible(true);
          queryClient.invalidateQueries(calendarsQueryKeys.lists());
        },
      },
      {
        onError: () => {
          setErrorVisible(true);
        },
      }
    );
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        setAlertVisible(false);
        onResolve();
      }, 1000);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (!isSuccess && isError) {
      setTimeout(() => {
        setErrorVisible(false);
        onResolve();
      }, 1000);
    }
  }, [isSuccess, isError]);

  const defaultValues = {
    user: '',
    assistant: '',
    url: '',
  };

  const useFormProps = {
    defaultValues,
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Cloud9 Credentials</DialogTitle>
      <HookForm useFormProps={useFormProps} onSubmit={handleCreateSubmit}>
        <DialogContent>
          {isAlertVisible && (
            <Alert severity="success">Cloud9 secret saved successfully.</Alert>
          )}
          {isErrorVisible && (
            <Alert severity="error">There was a problem while editing.</Alert>
          )}
          <Box display="flex" flexDirection="column" rowGap={2}>
            <TextField
              label="Username"
              type="text"
              fullWidth
              value={username || ''}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              fullWidth
              value={password || ''}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              label="Client-ID"
              type="text"
              fullWidth
              value={clientId || ''}
              onChange={(e) => setClientId(e.target.value)}
            />
            <TextField
              label="Cloud9 URL"
              type="text"
              defaultValue={url || ''}
              onChange={(e) => setUrl(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton type="submit" loading={isLoading} disabled={isSuccess}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};
