// TODO handle loading/error states
import {
  useMultiAccountUsersList,
  useUsersCurrent,
} from '../services/users/queries';
import { useUserOrganizationConnectionsList } from '../services/userOrganizationConnections/queries';
import { useMailboxesList } from '../services/mailboxes/queries';
import {
  useMailboxesDelete,
  useMailboxesEdit,
  useAuthorizedUserCreate,
  useAuthorizedUserRemove,
} from '../services/mailboxes/mutations';
import {
  useOrgDefaultCreate,
  useOrgDefaultEdit,
} from '../services/organizationDefaults/mutations';
import { useIsMutating, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import { useOrg } from '../../contexts/OrgProvider';
import { DeleteIcon, EditIcon, PlusIcon, SaveIcon } from '../theme/icons';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { fireDialog } from '../components/Dialog';
import HookForm, { FormProvider } from '../components/HookForm';
import { useForm } from 'react-hook-form';
// TODO
import GoogleIcon from '../../assets/images/btn_google_dark_normal_ios.svg';
import {
  EMAIL_ADDRESS,
  SENDER_NAME,
  ALIAS,
  createYupResolver,
} from '../components/HookForm/yupValidations';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Divider,
  Box,
  Alert,
  Link,
  Button,
  Grid,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { getSenderName } from '../services/mailboxes/mailboxesUtils';
import { useOrganizationDefaultsList } from '../services/organizationDefaults/queries';
import { useConditionalUseFormProps } from '../components/HookForm/hooks';

const USER_CHECKBOXES = 'userCheckboxes';

const ConnectedMailboxesSettingsCard = () => {
  const { id: orgId } = useOrg();
  const { data: currentUser } = useUsersCurrent();
  const {
    data: ownedMailboxes,
    isFetching: isLoadingMailboxes,
    isError: didFailLoadingMailboxes,
  } = useMailboxesList([
    ['owner_user_id', currentUser.id],
    ['owner_org_id', orgId],
  ]);

  const {
    data: userConnections,
    isLoading: isLoadingUserConnections,
    isError: didFailLoadingUserConnections,
  } = useUserOrganizationConnectionsList([['organization_id[]', orgId]]);
  const {
    data: users,
    isLoading: isLoadingUsers,
    isError: didFailLoadingUsers,
  } = useMultiAccountUsersList(
    !!userConnections
      ? userConnections.items?.map((uc) => ['id[]', uc.user_id])
      : [],
    {
      enabled: !isLoadingUserConnections && !!userConnections,
    }
  );
  const { data: orgDefaultsList, isError } = useOrganizationDefaultsList([
    ['organization_id[]', orgId],
  ]);

  const {
    mutate: createFriendlyFrom,
    isLoading: isCreatingFriendlyFrom,
    isError: isCreateFriendlyFromError,
    isSuccess: isSuccessCreateFriendlyFrom,
  } = useOrgDefaultCreate();

  const {
    mutate: editOrgDefault,
    isError: isEditError,
    isSuccess: isSuccessEditFriendlyFrom,
  } = useOrgDefaultEdit();

  const handleDeleteMailboxClick = (mailbox) => {
    fireDialog((promiseParams) =>
      DeleteMailboxDialog({ ...promiseParams, mailbox })
    );
  };

  const handleEditMailboxClick = (mailbox) => {
    const senderName = getSenderName(mailbox, users, currentUser);
    const defaultValues = getUserDefaultValues(
      users,
      mailbox,
      senderName,
      mailbox.alias || ''
    );
    return fireDialog((promiseProps) =>
      AuthorizedUsersDialog({
        ...promiseProps,
        mailbox,
        defaultValues,
        users,
      })
    );
  };

  const handleConnectMailboxClick = () => fireDialog(ConnectMailboxDialog);

  const getUserDefaultValues = (authorizedUsers, mailbox, senderName, alias) => {
    const userCheckboxes = authorizedUsers.reduce((defaults, { id }) => {
      const isAuthorizedChecked = !!mailbox.authorized_users.find(
        ({ user_id }) => user_id === id
      );
      return { ...defaults, [id]: isAuthorizedChecked };
    }, {});
    return { [USER_CHECKBOXES]: userCheckboxes, senderName, alias };
  };

  const [isExistFriendlyFrom, setIsExistFriendlyFrom] = useState('');

  const onSubmitFriendlyFrom = ({ friendly_from }) => {
    const postData = {
      organization_id: orgId,
      key: 'friendly_from',
      value: friendly_from,
    };

    if (isExistFriendlyFrom) {
      editOrgDefault({
        id: isExistFriendlyFrom.id,
        key: 'friendly_from',
        value: friendly_from,
      });
    } else {
      createFriendlyFrom({ ...postData });
    }
  };

  useEffect(() => {
    const findValue = orgDefaultsList?.find(({ key }) => key === 'friendly_from');

    if (findValue) {
      setIsExistFriendlyFrom(findValue);
    }
  }, [orgDefaultsList, setIsExistFriendlyFrom]);

  const defaultValues = {
    friendly_from: isExistFriendlyFrom.value,
  };

  const useDefaultsFormProps = useConditionalUseFormProps(
    {
      defaultValues,
      resolver: createYupResolver(defaultValues),
    },
    !!isExistFriendlyFrom
  );

  return (
    <SettingsCard showLoading={isLoadingMailboxes}>
      <SettingsCard.Header>Email</SettingsCard.Header>
      <SettingsCard.SubHeader>
        Connecting an email lets LeadSigma send messages from an address of your
        choice. If you do not connect one, LeadSigma will send email communications
        from an internal address.
      </SettingsCard.SubHeader>
      <SettingsCard.Main>
        {didFailLoadingMailboxes || didFailLoadingUsers ? (
          <Alert severity="error">
            There was a problem loading your email addresses. Try again.
          </Alert>
        ) : (
          <>
            {ownedMailboxes?.length == 0 ? (
              <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
                Add an email account to start sending and receiving with your email
                address.
              </Alert>
            ) : (
              <TableContainer sx={{ mb: 2 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>Sender Name</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ownedMailboxes?.map((mailbox) => {
                      return (
                        <TableRow key={mailbox.id}>
                          <TableCell width={225}>{mailbox.email_address}</TableCell>
                          <TableCell width={225}>
                            <Typography variant="body2">
                              {`${getSenderName(mailbox, users, currentUser)}`}
                            </Typography>
                          </TableCell>
                          <TableCell width={1}>
                            <Button
                              startIcon={<EditIcon />}
                              variant="text"
                              color="primary"
                              onClick={() => handleEditMailboxClick(mailbox)}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell width={1}>
                            <Button
                              startIcon={<DeleteIcon />}
                              sx={{ ml: 1 }}
                              variant="text"
                              color="error"
                              onClick={() => handleDeleteMailboxClick(mailbox)}
                            >
                              Delete
                            </Button>
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <Button onClick={handleConnectMailboxClick} startIcon={<PlusIcon />}>
              Connect an Email
            </Button>
          </>
        )}
        <hr />
        <SettingsCard.Header>Friendly From</SettingsCard.Header>
        <SettingsCard.SubHeader>
          Set a friendly from to override the name on sent email
        </SettingsCard.SubHeader>
        <SettingsCard.Main>
          {(isCreateFriendlyFromError || isEditError) && (
            <Alert sx={{ mb: 2 }} severity="error">
              Something went wrong, please try again later
            </Alert>
          )}
          {(isSuccessCreateFriendlyFrom || isSuccessEditFriendlyFrom) && (
            <Alert sx={{ mb: 2 }} severity="info">
              Successfully updated the friendly from.
            </Alert>
          )}
          <HookForm
            useFormProps={useDefaultsFormProps}
            onSubmit={onSubmitFriendlyFrom}
            useDirtyFormCheck={true}
          >
            <Grid container alignItems={'center'}>
              <HookForm.TextField name="friendly_from" label="Friendly From" />
              <LoadingButton
                type="submit"
                loading={isCreatingFriendlyFrom}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                sx={{ ml: 2 }}
              >
                Save
              </LoadingButton>
            </Grid>
          </HookForm>
        </SettingsCard.Main>
      </SettingsCard.Main>
    </SettingsCard>
  );
};

export default ConnectedMailboxesSettingsCard;

// -----------------------------------------------------------------
const ConnectMailboxDialog = ({ isOpen, onResolve, onReject }) => {
  const org = useOrg();
  const { data: user } = useUsersCurrent();

  const onConnectMailboxSubmit = (data) => {
    const { senderName, emailAddress } = { ...data };
    let namePart;
    if (senderName !== `${user.first_name} ${user.last_name}`) {
      namePart = `&sender_name=${encodeURIComponent(senderName)}`;
    } else {
      namePart = '';
    }

    window.location.href = `${
      process.env.REACT_APP_EMAIL_SERVICE
    }/connection_initiate?email_address=${encodeURIComponent(
      emailAddress
    )}${namePart}&user_id=${user.id}&organization_id=${org.id}`;

    onResolve();
  };
  const defaultValues = { [EMAIL_ADDRESS]: '', [SENDER_NAME]: '' };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Connect an Email</DialogTitle>
      <HookForm
        useFormProps={{
          defaultValues,
          resolver: createYupResolver(defaultValues),
        }}
        onSubmit={onConnectMailboxSubmit}
        useDirtyFormCheck
      >
        <DialogContent>
          <Alert severity="info">
            GSuite users, first read{' '}
            <Link
              underline="hover"
              target="_blank"
              href="https://sigmaleads.atlassian.net/wiki/spaces/LCS/pages/1297743882/Connected+Email"
            >
              this support document
            </Link>
          </Alert>
          <Box display="grid" direction="column" rowGap={2}>
            <HookForm.TextField name={EMAIL_ADDRESS} label="Email Address" />
            <HookForm.TextField name={SENDER_NAME} label="Sender Name" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="cancel" onClick={onReject}>
            Cancel
          </Button>
          <Button type="submit">Confirm</Button>
          <Button
            color="google"
            sx={{
              maxHeight: '36px',
              py: 0,
              pl: 0,
              pr: 1,
              borderRadius: 0.2,
              '& img': { dipslay: 'block', height: '100%' },
              '& .MuiButton-startIcon': { height: '40px', m: 0, p: 0, ml: -0.3 },
            }}
            type="submit"
            startIcon={<img src={GoogleIcon} alt="Sign in with Google" />}
          >
            Sign in with Google
          </Button>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

/* ANCHOR Authorized User Dialog */

var didFailMutatingMailbox = false; // NOTE
// --------------------------------------------------------
const AuthorizedUsersDialog = ({
  isOpen,
  onResolve,
  onReject,
  mailbox,
  defaultValues,
  users: authorizedUsers,
}) => {
  const queryClient = useQueryClient();
  const isMutatingMailboxes = useIsMutating(['mailboxes']);
  const { data: currentUser } = useUsersCurrent();

  const handleRefreshAll = () => {
    if (isMutatingMailboxes === 0) {
      queryClient.refetchQueries(['mailboxes']);
    }
  };

  const { mutate: editMailbox } = useMailboxesEdit({
    onSuccess: () => {
      if (!didFailMutatingMailbox && isMutatingMailboxes == 0) {
        handleRefreshAll();
        onResolve();
      }
    },
    onError: () => {
      didFailMutatingMailbox = true;
    },
  });
  const { mutate: createAuthorizedUser } = useAuthorizedUserCreate({
    onSuccess: () => {
      if (!didFailMutatingMailbox && isMutatingMailboxes == 0) {
        handleRefreshAll();
        onResolve();
      }
    },
    onError: () => {
      didFailMutatingMailbox = true;
    },
  });
  const { mutate: removeAuthorizedUser } = useAuthorizedUserRemove({
    onSuccess: () => {
      if (!didFailMutatingMailbox && isMutatingMailboxes == 0) {
        handleRefreshAll();
        onResolve();
      }
    },
    onError: () => {
      didFailMutatingMailbox = true;
    },
  });
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getAllCheckboxesBool = () => {
    let allSelectedBool = true;
    Object.values(getValues()[USER_CHECKBOXES]).forEach((isAuthorizedChecked) => {
      if (!isAuthorizedChecked) allSelectedBool = false;
    });
    return allSelectedBool;
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //TODO -need! -forms Needs serious consideration for different implementation. See HookForm readme
  const methods = useForm({
    defaultValues,
    resolver: createYupResolver({
      [SENDER_NAME]: defaultValues[SENDER_NAME],
      [ALIAS]: defaultValues[ALIAS],
    }),
  });
  const {
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { dirtyFields },
  } = { ...methods };
  // TODO use useWatch at some point if implementation not changed. ^
  useEffect(() => {
    // initial
    setIsAllSelected(getAllCheckboxesBool());
    const watchCheckboxes = watch(() => {
      setIsAllSelected(getAllCheckboxesBool());
    });
    return () => watchCheckboxes.unsubscribe();
    // watch change does not trigger on form change
  }, [watch]);

  const onEditMailboxesSubmit = (formFields) => {
    didFailMutatingMailbox = false;
    // using dirty fields from RHF to send needed api calls
    if (SENDER_NAME in dirtyFields || ALIAS in dirtyFields) {
      editMailbox({
        id: mailbox.id,
        sender_name: formFields.senderName,
        alias: formFields.alias,
      });
    }
    if (USER_CHECKBOXES in dirtyFields) {
      Object.keys(dirtyFields[USER_CHECKBOXES]).forEach((dirtyUserId) => {
        if (formFields[USER_CHECKBOXES][dirtyUserId]) {
          createAuthorizedUser({ mailbox_id: mailbox.id, user_id: dirtyUserId });
        } else {
          const authed = mailbox.authorized_users.find(
            ({ user_id }) => dirtyUserId === user_id
          );
          removeAuthorizedUser(authed.id);
        }
      });
    }
  };

  const handleSelectAllClick = (e) => {
    Object.entries(getValues()[USER_CHECKBOXES]).forEach(([userId, isAuthed]) => {
      if (e.target.value !== isAuthed)
        setValue(`${USER_CHECKBOXES}.${userId}`, e.target.checked, {
          shouldDirty: true,
        });
    });
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Edit Connected Email</DialogTitle>
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onEditMailboxesSubmit)}
        useDirtyFormCheck
      >
        <DialogContent>
          {didFailMutatingMailbox && (
            <Alert severity="error">
              There was a problem editing your connected email. Try again.
            </Alert>
          )}
          <Box display="grid" direction="column" rowGap={2}>
            <FormProvider.TextField name={SENDER_NAME} label="Sender Name" />
            <FormProvider.TextField name={ALIAS} label="Alias" />
          </Box>
          <Divider sx={{ mt: 2, mb: 1 }} />
          {authorizedUsers?.filter((user) => {
            return user.id != currentUser.id;
          })?.length == 0 ? (
            <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
              No authorized users for this connected email.
            </Alert>
          ) : (
            <>
              <Typography variant="subtitle1">Manage Authorized Users</Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="none" width={1}>
                        <Checkbox
                          checked={isAllSelected}
                          onChange={handleSelectAllClick}
                        />
                      </TableCell>
                      <TableCell>Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {authorizedUsers
                      ?.filter((user) => {
                        return user.id != currentUser.id;
                      })
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((user) => (
                        <TableRow key={user.id}>
                          <TableCell padding="none">
                            <FormProvider.Checkbox
                              key={user.id}
                              name={`${USER_CHECKBOXES}.${user.id}`}
                              label=""
                            />
                          </TableCell>
                          <TableCell>{`${user.first_name} ${user.last_name}`}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={authorizedUsers?.length - 1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="cancel" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton loading={isMutatingMailboxes > 0} type="submit">
            Confirm
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

/* ANCHOR Delete modal */

const DeleteMailboxDialog = ({ isOpen, onResolve, onReject, mailbox }) => {
  const {
    mutate: deleteMailbox,
    isError: didFailMutatingMailbox,
    isLoading: isMutatingMailbox,
  } = useMailboxesDelete();

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Remove Mailbox / {mailbox.email_address}</DialogTitle>
      <DialogContent>
        {didFailMutatingMailbox && (
          <Alert severity="error">
            There was a problem deleting your connected email. Try again.
          </Alert>
        )}
        Are you sure you want to delete this connected email? Other users' access to
        this connected email will be revoked and task sequences using this connected
        email will automatically default to use a generic email address we provide.
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          loading={isMutatingMailbox}
          onClick={() => deleteMailbox(mailbox.id, { onSuccess: onResolve })}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
