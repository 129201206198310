import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import HookForm from '../../HookForm';
import { createYupResolver } from '../../HookForm/yupValidations';
import {
  formatPhoneNumberE164,
  formatPhoneNumber,
} from '../../../services/textFormatting';

const PHONE = 'number';

export const Dial = ({
  handleMutateComponentListByParametersInUI,
  id,
  parameters,
  doSearchEmptyInputs,
  getUpdatedInputParams,
}) => {
  const [inputParameters, setInputParameters] = useState(
    getUpdatedInputParams(id)?.parameters || parameters
  );
  const defaultValues = {
    [PHONE]: formatPhoneNumber(inputParameters?.[PHONE] || ''),
  };

  const useFormProps = { defaultValues, resolver: createYupResolver(defaultValues) };

  const handleChange = (form) => {
    setInputParameters({
      ...inputParameters,
      [form.target.name]: formatPhoneNumberE164(form.target.value),
    });
    handleMutateComponentListByParametersInUI(
      { [form.target.name]: formatPhoneNumberE164(form.target.value) },
      id
    );
  };

  useEffect(() => {
    let neededParams =
      inputParameters && Object.keys(inputParameters).length > 0
        ? inputParameters
        : defaultValues;
    // need for back-end empty 'message: " " '
    neededParams = { ...neededParams, message: '' };
    handleMutateComponentListByParametersInUI(neededParams, id);
  }, []);

  return (
    <Box>
      <HookForm onChange={handleChange} useFormProps={useFormProps}>
        <Typography fontSize={18} fontWeight={400} mt={2}>
          This is where the phone will ring when customers dial your tracking number.
        </Typography>
        <Typography fontSize={16} fontWeight={500} mt={2} mb={1}>
          Forward Calls To
        </Typography>

        <HookForm.PhoneNumberField
          name={PHONE}
          label="Phone Number"
          showError={doSearchEmptyInputs && !inputParameters.number}
        />
      </HookForm>
    </Box>
  );
};
