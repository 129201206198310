import { React, useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import { Menu, MenuItem, CircularProgress, Box } from '@mui/material';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import {
  useSlotesCreate,
  useSlotesEdit,
  useSlotsDelete,
} from '../../api/slots/mutations';
import { useSlotsList } from '../../api/slots/queries';
import {
  useCalendarsList,
  useCalendarsListById,
} from '../services/calendars/queries';
import { useSlotesCopiesCreate } from '../../api/slotCopies/mutations';
import { useParams } from 'react-router-dom';
import { useAppointmentsList } from '../../api/appointments/queries';
import { SlotsCopy } from '../theme/icons';
import { LoadingButton } from '@mui/lab';
import { convertIsoToLocalDateTimeString } from '../services/dateTime';
import moment from 'moment';

const CalendarViewPage = () => {
  const { calendarID } = useParams();
  const { data: calendardata } = useCalendarsList([]);
  const [currentWeekDates, setCurrentWeekDates] = useState('');
  const { mutate: createSlots, isSuccess: slotsCreateSuccess } = useSlotesCreate();
  const { mutate: editSlots } = useSlotesEdit();
  const { mutate: deleteSlots } = useSlotsDelete();
  const [eventName, setEventName] = useState('');
  const [transformedData, setTransformedData] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [appointmentData, setAppointmentData] = useState([]);
  const [combinedSlotsAppointemntData, setCombinedSlotsAppointemntData] = useState(
    []
  );
  const { data: calendarDataById } = useCalendarsListById({
    calendar_id: calendarID,
  });
  const [appointmentDuration, setAppointmentDuration] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeIntervel, setTimeIntervel] = useState();
  const [calendarTimeZone, setcalendarTimeZone] = useState();
  const {
    mutate: createSlotsCopies,
    isLoading: slotsCopyloading,
    isSuccess: slotsCopiesCreateSuccess,
  } = useSlotesCopiesCreate();

  useEffect(() => {
    if (calendarDataById?.config_items) {
      const appointmentItem = calendarDataById.config_items.find(
        (item) => item.name === 'appointment_duration'
      );
      const timeIntervelItem = calendarDataById.config_items.find(
        (item) => item.name === 'interval_display'
      );
      setTimeIntervel(timeIntervelItem?.value);
      if (appointmentItem) {
        setAppointmentDuration(appointmentItem.value);
      }
    }
  }, [calendarDataById?.config_items]);

  const { data: slotsData, refetch: refetchSlots } = useSlotsList({
    calendar_id: calendarID,
    begins_at_starts: currentWeekDates.begins_at_starts,
    begins_at_ends: currentWeekDates.begins_at_ends,
    page_size: 1000,
  });

  const { data: appointments, refetch: refetchAppointment } = useAppointmentsList([
    {
      calendar_id: calendarID,
      begins_at_starts: currentWeekDates.begins_at_starts,
      begins_at_ends: currentWeekDates.begins_at_ends,
      page_size: 1000,
    },
  ]);

  useEffect(() => {
    if (appointments && appointments.items && Array.isArray(appointments.items)) {
      const appointment = appointments.items.map((item) => ({
        id: item.id,
        start: convertUTC(item?.begins_at, calendarDataById?.timezone),
        end: convertUTC(item?.ends_at, calendarDataById?.timezone),
        title: '',
        color: '#34D1B6',
        url: `/leads/${item.lead_id}`,
      }));
      setAppointmentData(appointment);
    }
  }, [appointments]);

  const convertUTC = (date, fromTz) => {
    date = date.replace('Z', '');
    const localTime = moment.tz(date, fromTz);
    const utcTime = localTime.clone().utc();
    const utcTimeFormat = utcTime.format('YYYY-MM-DDThh:mm:ssZ');
    const dateTimeWithZ = utcTimeFormat.replace(/[-+]\d{2}:\d{2}$/, 'Z');

    return dateTimeWithZ;
  };

  useEffect(() => {
    if (
      calendardata &&
      Array.isArray(calendardata.items) &&
      calendardata.items.length > 0
    ) {
      const event_name = calendardata.items[0].name;
      setEventName(event_name);
    }
  }, [calendardata]);

  const handleDatesSet = (info) => {
    // Extracting start and end dates of the currently visible week
    const startDate = info.view.currentStart;
    const endDate = info.view.currentEnd;

    const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = endDate.toISOString().split('T')[0];
    // Set the current week dates to state
    setCurrentWeekDates({
      begins_at_starts: formattedStartDate,
      begins_at_ends: formattedEndDate,
    });
  };

  useEffect(() => {
    if (currentWeekDates.begins_at_starts && currentWeekDates.begins_at_ends) {
      refetchSlots({
        calendar_id: calendarID,
        begins_at_starts: currentWeekDates.begins_at_starts,
        begins_at_starts: currentWeekDates.begins_at_starts,
      });
      refetchAppointment({
        calendar_id: calendarID,
        begins_at_starts: currentWeekDates.begins_at_starts,
        begins_at_starts: currentWeekDates.begins_at_starts,
      });
    }
  }, [currentWeekDates]);

  const handleSelectedDates = async (info) => {
    if (eventName !== null) {
      if (info.startStr && info.endStr) {
        setIsLoading(true);
        const startDateTime = info.startStr;
        const startTime = new Date(startDateTime);
        let endTime;

        if (appointmentDuration) {
          const appointmentDuration_min = Math.floor(appointmentDuration / 60);
          endTime = new Date(
            startTime.getTime() + appointmentDuration_min * 60 * 1000
          ); // Convert to minutes
        } else {
          const endDateTime = info.endStr;
          endTime = new Date(endDateTime);
          const timeDifferenceMs = endTime.getTime() - startTime.getTime();
          const timeDifferenceHours = timeDifferenceMs / (1000 * 3600);

          if (timeDifferenceHours < 2) {
            endTime.setTime(startTime.getTime() + 2 * 60 * 60 * 1000); // 2 hours in milliseconds
          }
        }
        createSlots(
          {
            begins_at: startDateTime,
            ends_at: convertIsoToLocalDateTimeString(endTime),
            calendar_id: calendarID,
          },
          {
            onSuccess: () => {
              setTimeout(() => {
                setIsLoading(false);
              }, 1000);
            },
          }
        );
      }
    }
  };

  const handleEventResize = (info) => {
    editSlots({
      begins_at: info.event.startStr,
      ends_at: info.event.endStr,
      id: info.event.id,
    });
  };

  useEffect(() => {
    if (slotsData && slotsData.items && Array.isArray(slotsData.items)) {
      const transformed = slotsData.items.map((item) => ({
        id: item.id,
        start: item.begins_at,
        end: item.ends_at,
        title: '',
        calendar_id: item.calendar_id,
      }));
      setTransformedData(transformed);
    }
  }, [slotsData]);

  useEffect(() => {
    if (
      calendardata &&
      Array.isArray(calendardata.items) &&
      calendardata.items.length > 0
    ) {
      const event_name = calendardata.items[0].name;
      setEventName(event_name);
    }
  }, [calendardata]);

  const handleContextMenu = (event) => {
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleEventClick = (info) => {
    if (!info.event.url) {
      setSelectedEvent(info.event.id);
      handleContextMenu(info.jsEvent);
    }
  };

  const handleEventDelete = () => {
    if (selectedEvent) {
      deleteSlots(selectedEvent);
      setSelectedEvent(null);
      handleClose();
    }
  };

  useEffect(() => {
    // Combine transformedData and appointmentData when either of them changes
    setCombinedSlotsAppointemntData([...transformedData, ...appointmentData]);
  }, [transformedData, appointmentData]);

  const handleDateClick = (arg) => {
    // Set the selected date when a day cell is clicked
    setSelectedDate(arg.date);
  };

  const customDayCellContent = (arg, is) => {
    const isSelected =
      selectedDate && arg.date.toDateString() === selectedDate.toDateString();

    return (
      <div style={{ position: 'relative' }}>
        {isSelected && isLoading && (
          <div style={{ position: 'relative' }}>
            <CircularProgress />
          </div>
        )}
      </div>
    );
  };

  const copyButtonHandler = () => {
    createSlotsCopies(
      {
        begins_at: currentWeekDates.begins_at_starts,
        ends_at: currentWeekDates.begins_at_ends,
        calendar_id: calendarID,
      },
      {
        onSuccess: () => {
          clickOnNextButton();
        },
      }
    );
  };

  const clickOnNextButton = () => {
    document
      .getElementsByClassName('fc-next-button fc-button fc-button-primary')[0]
      .click();
  };

  const timeZoneAbbreviations = {
    'Pacific/Honolulu': 'HST',
    'America/Los_Angeles': 'PDT',
    'America/Phoenix': 'MST',
    'America/Denver': 'MDT',
    'America/Chicago': 'CDT',
    'America/New_York': 'EDT',
    'America/Puerto_Rico': 'AST',
  };

  useEffect(() => {
    const timeZone_code = timeZoneAbbreviations[calendarDataById?.timezone];
    setcalendarTimeZone(timeZone_code);
  }, [calendarDataById]);

  return (
    <SettingsCard>
      <div onContextMenu={handleContextMenu}>
        <Box
          sx={{
            heigsxht: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: -4,
          }}
        >
          <div>
            <h6>{calendarDataById?.timezone}</h6>
          </div>
          <LoadingButton
            sx={{ ml: 2 }}
            variant="outlined"
            onClick={copyButtonHandler}
            startIcon={<SlotsCopy />}
            loading={slotsCopyloading}
          >
            Copy
          </LoadingButton>
        </Box>
        <FullCalendar
          initialView="timeGridWeek"
          headerToolbar={{
            start: 'prev,next,title',
            end: '',
          }}
          timeZone={calendarTimeZone || 'UTC'}
          slotDuration={timeIntervel || '00:05:00'}
          selectable={true}
          editable={true}
          eventResizableFromStart={true}
          eventStartEditable={false}
          droppable={true}
          allDaySlot={false}
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          events={combinedSlotsAppointemntData}
          eventColor="#6f42c1"
          select={handleSelectedDates}
          eventClick={handleEventClick}
          datesSet={handleDatesSet}
          dayCellContent={customDayCellContent}
          dateClick={handleDateClick}
          scrollTime={'09:00:00'}
          eventResize={handleEventResize}
          timeZoneParam={calendarTimeZone || 'UTC'}
          dayHeaderContent={function (args) {
            const toolbarTitleElement = document.querySelector(
              'div.fc-toolbar-chunk h2.fc-toolbar-title'
            );
            if (toolbarTitleElement) {
              toolbarTitleElement.innerHTML = calendarDataById?.name || '';
            }
            return args.text;
          }}
        />
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={handleEventDelete}>Delete Event</MenuItem>
        </Menu>
      </div>
    </SettingsCard>
  );
};
export default CalendarViewPage;
