import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import parsePhoneNumber from 'libphonenumber-js';
import moment from 'moment';
import CurrencyInput from '../common/ReactCurrencyInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BiInfoCircle } from 'react-icons/bi';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Prompt } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { emailValidation, statuses } from '../../util/Constants';
import { formatName } from '../../MUI/services/textFormatting';
import Phone from '../../clients/Phone';
import Email from '../../clients/Email';
import Lead from '../../clients/Lead';
import InputMask from 'react-input-mask';
import {
  mostRecentItem,
  stage,
  primaryPhone,
  primaryEmail,
  mostRecentOpportunityStatus,
  mostRecentOpportunity,
  getPrimaryData,
} from '../../util/Details';
import { useHistory } from 'react-router';
import { PENDING, WON } from '../tasks/TaskUtils';
import LeadLostModal from '../leads/LeadLostModal';
import RevealNumberModal from '../leads/RevealNumberModal';
import { useOpportunitiesEdit } from '../../api/opportunities/opportunitiesMutations';
import { useLostReasons } from '../../api/lostReasons/lostReasonsQueries';
import { useTeamsList } from '../../MUI/services/teams/queries';
import { useCustomFields } from '../../api/customFields/queries';
import { dateToPrettyDateTimeString } from '../../MUI/services/dateTime';
import {
  DATE_TIME_TYPE,
  OPTION_TYPES,
  FREESOLO_OPTION_TYPES,
} from '../../api/customFields/customFieldsUtils';
import { useSourcesList } from '../../MUI/services/sources/queries';
import { leadsQueryKeys } from '../../MUI/services/leads/queries';
import { useQueryClient } from 'react-query';
import { useUsersCurrent, useUsersList } from '../../MUI/services/users/queries';
import { useUserOrganizationConnectionsList } from '../../MUI/services/userOrganizationConnections/queries';
import { useOrg } from '../../contexts/OrgProvider';
import { tasksQueryKeys } from '../../MUI/services/tasks/queries';
import { eventsQueryKeys } from '../../MUI/services/events/queries';
import { useTaskSequencesList } from '../../MUI/services/taskSequences/queries';
import AppContext from '../../contexts/AppContext';
import {
  Autocomplete,
  Button as MUIButton,
  TextField,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { fireDialog } from '../../MUI/components/Dialog';
import { BadPhoneIcon } from '../../MUI/theme/icons';

const EditDetails = ({
  lead,
  isEditing, // TODO Create helper function and combine with isLoadings to keep from waiting on queries only Edit needs
  user,
  setIsSaving,
  setIsEditing,
  onSave,
  setOnSave,
  isCancelEdit,
  setIsCancelEdit,
  setShowPhone,
}) => {
  const [phone, setPhone] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [teamId, setTeamId] = useState();
  const [teamName, setTeamName] = useState('');
  const [source, setSource] = useState({ id: null });
  const [formattedPhone, setFormattedPhone] = useState('');
  const [hiddenPhone, setHiddenPhone] = useState('');
  const [opportunityValue, setOpportunityValue] = useState(0);
  const [selectedOpportunityStatus, setSelectedOpportunityStatus] = useState(null);
  const [opportunityStatusId, setOpportunityStatusId] = useState();
  const [formattedValue, setFormattedValue] = useState('');
  const [taskSequence, setTaskSequence] = useState(lead.task_sequence);
  const [leadCopy, setLeadCopy] = useState(null);
  const [leadCustomFieldValues, setLeadCustomFieldValues] = useState(null);
  const [customFieldNameValuePairs, setCustomFieldNameValuePairs] = useState(null);
  const [newSequenceId, setNewSequenceId] = useState();
  const [showLeadLostModal, setShowLeadLostModal] = useState(false);
  const [sortedSequences, setSortedSequences] = useState();
  const [showRevealModal, setShowRevealModal] = useState(false);
  const [showNumber, setShowNumber] = useState(false);
  const [isBadPhone, setIsBadPhone] = useState(false);
  const [phoneError, setPhoneError] = useState();
  const { task } = useContext(AppContext);
  //TODO should canceled be included?
  const isTaskOperationDisabled = !!task?.completed_at || task?.canceled_at;
  const lostReasonsParams = [['organization_id[]', lead.organization_id]];
  const queryClient = useQueryClient();
  const [assignedUserId, setAssignedUserId] = useState(lead.assigned_user_id);
  const history = useHistory();

  const { id: orgId, isMasterAccount } = useOrg();
  if (isMasterAccount && lead.organization_id !== orgId) {
    lostReasonsParams.push(['organization_id[]', orgId]);
  } else if (!isMasterAccount) {
    lostReasonsParams.push(['find_globl_lost_reasons', true]);
  }

  const {
    data: userConnections,
    isLoading: isLoadingUserConnections,
    isError: didFailLoadingUserConnections,
  } = useUserOrganizationConnectionsList([
    ['organization_id[]', lead.organization_id],
  ]);
  const { data: currentUser } = useUsersCurrent();
  const {
    data: users,
    isLoading: isLoadingUsers,
    isError: didFailLoadingUsers,
  } = useUsersList(
    !!userConnections
      ? userConnections.items?.map((uc) => ['id[]', uc.user_id])
      : [],
    {
      enabled: !isLoadingUserConnections && !!userConnections,
    }
  );
  const { data: customFields } = useCustomFields([
    ['organization_id[]', lead.organization_id],
  ]);
  const { data: taskSequences } = useTaskSequencesList([
    ['organization_id[]', lead.organization_id],
  ]);

  const { data: teamsList } = useTeamsList(
    [['organization_id[]', lead.organization_id]],
    {
      onSuccess: (data) => {
        if (data && data.length > 0) {
          setTeamName(data.find((x) => x.id == lead.team_id)?.name);
        }
      },
    }
  );
  const { data: lostReasons } = useLostReasons(lostReasonsParams);
  const { data: sources } = useSourcesList([
    ['organization_id[]', lead?.organization_id],
  ]);
  const { mutate: updateLeadStatus } = useOpportunitiesEdit({
    onSuccess: () => {
      if (selectedOpportunityStatus === PENDING) {
        Lead.edit({
          id: lead.id,
          task_sequence_id: newSequenceId,
        }).then((res) => {
          queryClient.invalidateQueries(leadsQueryKeys.all);
          queryClient.invalidateQueries(tasksQueryKeys.all);
          queryClient.invalidateQueries(eventsQueryKeys.all);
        });
      } else {
        queryClient.invalidateQueries(leadsQueryKeys.all);
        queryClient.invalidateQueries(tasksQueryKeys.all);
        queryClient.invalidateQueries(eventsQueryKeys.all);
      }
    },
    onSettled: () => {
      if (history.location.pathname.includes('leads'))
        history.push({
          state: 'refreshLeads',
        });
      else
        history.push({
          pathname: '/tasks',
          state: 'refreshLeads',
        });
    },
  });

  useEffect(() => {
    setShowNumber(false);
  }, [lead]);

  useEffect(() => {
    if (taskSequences) {
      const tempSortedSequences = taskSequences?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      if (tempSortedSequences.length > 0)
        setNewSequenceId(tempSortedSequences[0].id);
      if (!sortedSequences) setSortedSequences(tempSortedSequences);
    }
  }, [taskSequences]);

  useEffect(() => {
    createFieldNameValuePairs();
  }, [customFields, leadCustomFieldValues]);

  useEffect(() => {
    if (showNumber) {
      setFormattedPhone(hiddenPhone);
    }
  }, [showNumber]);

  useEffect(() => {
    if (showNumber) {
      return;
    }
    if (formattedPhone?.length > 4 && !formattedPhone.endsWith('XXXX')) {
      setHiddenPhone(formattedPhone);
      setFormattedPhone(`${formattedPhone.slice(0, -4)}XXXX`);
    }
  }, [formattedPhone]);

  const createFieldNameValuePairs = () => {
    if (customFields) {
      let tempFields = customFields.map((contextField) => {
        let leadField = leadCustomFieldValues?.find(
          (leadField) => leadField.custom_field_id === contextField.id
        );
        if (leadField) {
          return {
            ...leadField,
            name: contextField.name,
            data_type: contextField.data_type,
            options_data: contextField.options_data,
          };
        } else
          return {
            name: contextField.name,
            custom_field_id: contextField.id,
            data_type: contextField.data_type,
            options_data: contextField.options_data,
          };
      });
      setCustomFieldNameValuePairs(tempFields);
    }
  };

  useEffect(() => {
    if (!isEditing) createFieldNameValuePairs();
  }, [isEditing]);

  useEffect(() => {
    if (lead) {
      setAllThings(lead);
      setLeadCopy(copyLead(lead));
      if (lead.phones && lead.phones.length > 0) {
        setIsBadPhone(lead.phones[0].is_valid === false);
        setPhoneError(lead.phones[0].validation_error);
      }
    }
  }, [lead]);

  useEffect(() => {
    if (isCancelEdit) {
      setAllThings(leadCopy);
      setIsCancelEdit(false);
    }
  }, [isCancelEdit]);

  useEffect(() => {
    if (onSave) {
      setIsSaving(true);
      const newLead = { ...lead };
      newLead.first_name = firstName;
      newLead.last_name = lastName;
      newLead.team_id = teamId || (teamsList && teamsList[0].id);
      newLead.source = source;
      if (taskSequence) {
        newLead.task_sequence = taskSequence;
      } else {
        delete newLead.task_sequence;
      }

      let opportunities = [];
      if (lead.opportunities.length > 0) {
        opportunities.push({
          value_cents: opportunityValue * 100,
          id: lead.opportunities[0].id,
        });
      }

      const primary = primaryPhone(lead);
      const tempPhone = phone?.replace(/[^0-9]/g, '');
      if (primary) {
        Phone.edit({
          id: primary.id,
          number: tempPhone,
        });
        newLead.phones[0].number = phone;
      } else {
        Phone.create({
          number: tempPhone,
          lead_id: lead.id,
          label: 'mobile',
          is_primary: true,
        });
      }

      const _email = primaryEmail(lead);
      if (_email && emailValidation.test(email)) {
        Email.edit({
          id: _email.id,
          lead_id: lead.id,
          email: email,
        });
        newLead.email_addresses[0].email = email;
      } else {
        if (emailValidation.test(email))
          Email.create({
            lead_id: lead.id,
            is_primary: true,
            email,
          });
      }
      let endUserId = assignedUserId;
      if (users && !users.find((u) => u.id == assignedUserId)) {
        endUserId = null; // Fix orphaned assigned user id
      }

      Lead.edit({
        id: lead.id,
        first_name: firstName,
        last_name: lastName,
        team_id: teamId || (teamsList && teamsList[0].id),
        source_id: source ? source.id : '',
        task_sequence_id: taskSequence ? taskSequence.id : '',
        assigned_user_id: endUserId,
        custom_field_values: customFieldNameValuePairs
          .filter((field) => field.value !== null || field.value !== undefined)
          .map((field) => {
            if (field.id) return field;
            return { custom_field_id: field.custom_field_id, value: field.value };
          }),
        opportunities: opportunities,
      }).then((data) => {
        setIsEditing(false);
        setIsSaving(false);
        setOnSave(false);
        setTeamName(teamsList.find((x) => x.id == data.team_id)?.name);
        queryClient.invalidateQueries(leadsQueryKeys.all);
        queryClient.invalidateQueries(tasksQueryKeys.all);
        history.push({ state: 'refreshLeads' });
      });
    }
  }, [onSave]);
  const copyLead = (lead) => JSON.parse(JSON.stringify(lead));

  const setAllThings = (lead) => {
    setLeadCustomFieldValues(JSON.parse(JSON.stringify(lead.custom_field_values)));
    setFirstName(lead.first_name);
    setLastName(lead.last_name);
    const status = mostRecentOpportunityStatus(lead);
    setOpportunityValue(mostRecentOpportunity(lead));
    setOpportunityStatusId(
      parseInt(Object.keys(statuses).find((key) => statuses[key] === status))
    );
    setSource(lead.source);
    setAssignedUserId(lead.assigned_user_id);

    setFormattedValue(
      lead &&
        mostRecentOpportunity(lead).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
    );
    setTeamId(lead.team_id);
    if (teamsList) {
      setTeamName(teamsList.find((x) => x.id == lead.team_id)?.name);
    }

    const primary = primaryPhone(lead);
    if (primary) {
      const phoneNumber = parsePhoneNumber(primary.number);
      if (phoneNumber) {
        setPhone(phoneNumber.formatNational());
        setFormattedPhone(phoneNumber.formatNational());
      } else {
        setFormattedPhone(primary.number);
        setPhone(primary.number);
      }
    } else {
      setFormattedPhone();
      setPhone();
    }
    const _email = primaryEmail(lead);
    if (_email) {
      setEmail(_email.email);
    } else {
      setEmail();
    }
    setTaskSequence(lead.task_sequence);
  };

  const handleUpdateLeadStatus = (status) => {
    updateLeadStatus({
      id: lead.opportunities[0].id,
      lead_id: lead.id,
      status,
      user_id: currentUser.id,
    });
    setSelectedOpportunityStatus(null);
  };

  const sourceClick = (options) => {
    setSource(options[0]);
  };
  const lastContacted = () => {
    let last = [];
    [lead.calls, lead.emails, lead.texts].forEach((comms) => {
      let recentMessage = mostRecentItem(comms);
      if (recentMessage) {
        last.push(recentMessage);
      }
    });
    let lastMessage = mostRecentItem(last);
    return lastMessage ? moment(lastMessage.created_at).format('LL') : 'Never';
  };

  const updateTeam = (e) => {
    setTeamId(e.target.value);
  };

  const teamsAsOptions = () => {
    if (!teamsList) {
      return;
    }

    return teamsList.map((team) => {
      return (
        <option value={team.id} selected={team.id == teamId}>
          {team.name}
        </option>
      );
    });
  };

  const updateOpportunityValue = (e, maskedvalue, floatvalue) => {
    setOpportunityValue(floatvalue);
    setFormattedValue(
      parseFloat(floatvalue).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    );
  };
  const updateFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const updateLastName = (e) => {
    setLastName(e.target.value);
  };

  const updateEmail = (e) => {
    setEmail(e.target.value);
  };

  const updatePhone = (e) => {
    setPhone(e.target.value);
    const phoneNumber = parsePhoneNumber(e.target.value);
    if (phoneNumber) {
      setFormattedPhone(phoneNumber.formatNational());
    } else {
      setFormattedPhone(e.target.value);
    }
  };

  const updateTaskSequence = (e) => {
    setTaskSequence(taskSequences.find((ts) => ts.id == e.target.value));
  };

  const handleTaskSequenceUpdate = (element) => {
    fireDialog((promiseProps) =>
      updateTaskSequenceDialog({
        ...promiseProps,
        element,
      })
    );
  };

  const updateTaskSequenceDialog = ({ isOpen, onResolve, onReject, element }) => {
    const changeTaskSequence = (element) => {
      updateTaskSequence(element);
      onResolve();
    };

    const resetTaskSequence = (element) => {
      document.getElementById('taskSequenceSelector').value = taskSequence?.id;
      onReject();
    };

    return (
      <Dialog open={isOpen} onClose={onReject}>
        <DialogTitle>Change Task Sequence</DialogTitle>
        <DialogContent>
          You are editing the lead to change the task sequence. All non-completed,
          tasks in the sequence will be deleted and new tasks will be created
          regardless of lead status. Are you sure you want to continue?
        </DialogContent>
        <DialogActions>
          <MUIButton variant="text" onClick={resetTaskSequence}>
            No
          </MUIButton>
          <MUIButton variant="contained" onClick={() => changeTaskSequence(element)}>
            Yes
          </MUIButton>
        </DialogActions>
      </Dialog>
    );
  };

  const taskSequenceOptions = () => {
    let o = [
      <option key={-1} value={-1}>
        ---
      </option>,
    ];
    taskSequences?.map((ts) => {
      o.push(
        <option value={ts.id} key={ts.id}>
          {ts.name}
        </option>
      );
    });
    return o;
  };

  const updateAssignedUser = (options) => {
    if (options && options.length > 0) setAssignedUserId(options[0].id);
    else setAssignedUserId(null);
  };

  const leadAssignedUserName = () => {
    if (lead.assigned_user_id == currentUser.id) return 'You';
    return formatName(lead.assigned_user.first_name, lead.assigned_user.last_name);
  };

  // ANCHOR Update custom field internally
  const updateCustomFields = (value, fieldID) => {
    setCustomFieldNameValuePairs((prev) => {
      const temp = [...prev];
      const fV = temp.find((fieldValue) => fieldValue.custom_field_id === fieldID);
      fV.value = value;
      return temp;
    });
  };

  const prettifyCustomFieldValue = (field) => {
    if (field.data_type == DATE_TIME_TYPE) {
      return dateToPrettyDateTimeString(field.value);
    }
    return emptyStringPad(field.value);
  };

  const formatIsoStringToDateTimeLocal = (isoString) => {
    if (!isoString) return '';
    return moment(isoString).local().format('YYYY-MM-DDTHH:mm');
  };

  const renderCustomFields = () =>
    customFieldNameValuePairs?.map((field, index) => (
      <React.Fragment key={index}>
        <dt className={'col-6 py-1'}>{field.name}</dt>
        <dd className={'col-6 text-break'}>
          {isEditing ? (
            <>
              {field.data_type == DATE_TIME_TYPE && (
                <Form.Control
                  type="datetime-local"
                  label={field.name}
                  value={formatIsoStringToDateTimeLocal(field.value)}
                  onChange={(e) =>
                    updateCustomFields(
                      new Date(e.target.value).toGMTString(),
                      field.custom_field_id
                    )
                  }
                />
              )}
              {field.data_type == 'string' && (
                <input
                  type={'text'}
                  className={'form-control'}
                  value={field.value}
                  onChange={(e) =>
                    updateCustomFields(e.target.value, field.custom_field_id)
                  }
                />
              )}
              {OPTION_TYPES.includes(field.data_type) && (
                <Autocomplete
                  size="small"
                  forcePopupIcon={false}
                  value={field.value ?? null}
                  sx={{ width: '100%' }}
                  onChange={(_, value) => {
                    updateCustomFields(value, field.custom_field_id);
                  }}
                  onBlur={(e) => {
                    if (FREESOLO_OPTION_TYPES.includes(field.data_type)) {
                      updateCustomFields(e.target.value, field.custom_field_id);
                    }
                  }}
                  freeSolo={FREESOLO_OPTION_TYPES.includes(field.data_type)}
                  getOptionLabel={(o) => o || ''}
                  options={field.options_data ?? []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder=""
                      fullWidth
                      sx={{ fontSize: 14, '& legend': { display: 'none' } }}
                    />
                  )}
                  isOptionEqualToValue={(option, value) => {
                    return option && value && option === value;
                  }}
                />
              )}
            </>
          ) : (
            prettifyCustomFieldValue(field)
          )}
        </dd>
      </React.Fragment>
    ));

  const emptyStringPad = (value) => {
    if (value) return value;
    return '\u00A0';
  };

  return (
    <>
      {showLeadLostModal && (
        <LeadLostModal
          lead={lead}
          organization={{ id: orgId, isMasterAccount }}
          showLeadLostModal={showLeadLostModal}
          setShowLeadLostModal={setShowLeadLostModal}
        />
      )}
      {showRevealModal && (
        <RevealNumberModal
          show={showRevealModal}
          setShow={setShowRevealModal}
          setShowPhone={setShowPhone}
          setShowNumber={setShowNumber}
        />
      )}
      <Modal
        show={
          selectedOpportunityStatus !== null &&
          selectedOpportunityStatus !== opportunityStatusId
        }
        className="modal-sigma"
        onHide={() => {
          setSelectedOpportunityStatus(null);
        }}
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title className="text-center">Update Lead Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOpportunityStatus !== PENDING && (
            <div className="text-center mb-2">
              Updating lead status will cancel current and future tasks.
            </div>
          )}
          <div className="text-center mb-2">{`Are you sure you want to change the status of this lead to: `}</div>
          <h6 className="text-center">{`${
            statuses[selectedOpportunityStatus] ?? ''
          }?`}</h6>
          {selectedOpportunityStatus === PENDING && (
            <div className="p-2 text-center">
              <p className="mb-2">
                You will need to select a new sequence for the lead for follow up
                tasks to be generated!
              </p>
              <Form.Control
                as="select"
                onChange={(e) => setNewSequenceId(e.target.value)}
              >
                {sortedSequences.map((sequence) => (
                  <option key={sequence.id} value={sequence.id}>
                    {sequence.name}
                  </option>
                ))}
              </Form.Control>
            </div>
          )}
          <Modal.Footer className="px-0">
            <Row noGutters className="w-100">
              <Col xs={6} className="px-3">
                <Button
                  className="btn btn-light btn-block"
                  onClick={() => setSelectedOpportunityStatus(null)}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs={6} className="px-3">
                <Button
                  className="btn btn-sigma-primary btn-block"
                  onClick={() => handleUpdateLeadStatus(selectedOpportunityStatus)}
                >
                  Update
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
      <Prompt
        when={isEditing}
        message={(location) => {
          if (location.pathname.includes('logout')) return true;
          return 'You have unsaved changes, are you sure you would like to leave this page?';
        }}
      />
      <Row noGutters className="mb-3">
        <Col xs={12} sm={4} className="pr-0 pr-sm-2 py-1">
          <Button
            disabled={isTaskOperationDisabled}
            onClick={() => {
              setSelectedOpportunityStatus(PENDING);
            }}
            className={`w-100 px-0 ${
              opportunityStatusId === 0
                ? 'btn-sigma-secondary'
                : 'btn-sigma-purple-border'
            }`}
          >
            Pending
          </Button>
        </Col>
        <Col xs={12} sm={4} className="px-0 px-sm-1 py-1">
          <Button
            disabled={isTaskOperationDisabled}
            onClick={() => {
              setSelectedOpportunityStatus(WON);
            }}
            className={`w-100 px-0 ${
              opportunityStatusId === 1
                ? 'btn-sigma-green'
                : 'btn-sigma-green-border'
            }`}
          >
            Lead Won
          </Button>
        </Col>
        <Col xs={12} sm={4} className="pl-0 pl-sm-2 py-1">
          <Button
            disabled={isTaskOperationDisabled}
            onClick={() => {
              if (opportunityStatusId !== 2) setShowLeadLostModal(true);
            }}
            className={`w-100 px-0 ${
              opportunityStatusId === 2 ? 'btn-sigma-red' : 'btn-sigma-red-border'
            }`}
          >
            Lead Lost
          </Button>
        </Col>
      </Row>
      <Row noGutters className="edit-details-fields-list">
        <dl className={'row m-0 sigma-list w-100'}>
          <dt className={'col-6'}>First Name</dt>
          <dd className={'col-6 text-break'}>
            {isEditing ? (
              <input
                type={'text'}
                className={'form-control'}
                value={firstName}
                onChange={updateFirstName}
              />
            ) : (
              lead.first_name
            )}
          </dd>

          <dt className={'col-6'}>Last Name</dt>
          <dd className={'col-6 text-break'}>
            {isEditing ? (
              <input
                type={'text'}
                className={'form-control'}
                value={lastName}
                onChange={updateLastName}
              />
            ) : (
              lead.last_name
            )}
          </dd>

          <dt className={'col-6'}>Phone</dt>
          <dd className={'col-6 text-break'}>
            {isBadPhone && (
              <Tooltip title={phoneError}>
                <BadPhoneIcon color="error" fontSize="small" sx={{ mr: 1 }} />
              </Tooltip>
            )}
            {isEditing ? (
              <InputMask
                mask="(999) 999-9999"
                className="form-control"
                placeholder={'Phone'}
                value={phone}
                onChange={updatePhone}
              />
            ) : (
              <>
                <span>
                  {formattedPhone ||
                    (lead &&
                      lead.phones &&
                      lead.phones.length > 0 &&
                      JSON.stringify(getPrimaryData(lead.phones).number))}
                </span>
                <BiInfoCircle
                  className={'ml-2 mb-1'}
                  size="18"
                  onClick={() => setShowRevealModal(true)}
                />
              </>
            )}
          </dd>

          <dt className={`col-lg-6 col-md-6 ${isEditing ? 'col-6' : 'col-4'}`}>
            Email
          </dt>
          <dd className={`col-lg-6 col-md-6 ${isEditing ? 'col-6' : 'col-8'}`}>
            {isEditing ? (
              <input
                type={'text'}
                className={'form-control'}
                value={email}
                onChange={updateEmail}
              />
            ) : (
              emptyStringPad(email) ||
              (lead &&
                lead.email_addresses &&
                lead.email_addresses.length > 0 &&
                emptyStringPad(
                  JSON.stringify(getPrimaryData(lead.email_addresses).email)
                ))
            )}
          </dd>
          {lead?.opportunities[0].lost_reason_id && (
            <>
              <dt className={'col-6'}>Reason Lost</dt>
              <dd className={'col-6 text-break'}>
                {lead?.opportunities[0]?.lost_reason?.name}
              </dd>
              <dt className={'col-6'}>Reason Lost Details</dt>
              <dd className={'col-6 text-break'}>
                {lead?.opportunities[0]?.lost_details ?? 'None'}
              </dd>
            </>
          )}
          <dt className={'col-6'}>Lead Source</dt>
          {isEditing ? (
            <dd className={'col-6 text-break'}>
              <Typeahead
                clearButton
                id={'source_search'}
                labelKey="name"
                allowNew={false}
                onChange={sourceClick}
                options={sources || []}
                placeholder="Source"
                defaultSelected={
                  sources ? sources.filter((s) => s.id == lead.source?.id) : []
                }
                renderMenuItemChildren={(option, props) => option.name}
              />
            </dd>
          ) : (
            <dd className={'col-6 text-break'}>
              {lead.source ? lead.source.name : '-'}
            </dd>
          )}

          <dt className={'col-6'}>Task Sequence</dt>
          <dd className={'col-6 text-break'}>
            {isEditing ? (
              <Form.Control
                as="select"
                onChange={handleTaskSequenceUpdate}
                defaultValue={taskSequence ? taskSequence.id : -1}
                id="taskSequenceSelector"
              >
                {taskSequenceOptions()}
              </Form.Control>
            ) : lead.task_sequence != undefined ? (
              stage(lead.task_sequence)
            ) : (
              '-'
            )}
          </dd>

          {teamsList && teamsList.length > 0 && (
            <>
              <dt className={'col-6'}>Team</dt>
              <dd className={'col-6 text-break'}>
                {isEditing ? (
                  <Form.Control as="select" onChange={updateTeam}>
                    {teamsAsOptions()}
                  </Form.Control>
                ) : (
                  <span>{teamName}</span>
                )}
              </dd>
            </>
          )}

          {!isLoadingUserConnections && !isLoadingUsers && (
            <>
              {isEditing ? (
                <>
                  <dt className={'col-6'}>Assigned User</dt>
                  <dd className={'col-6 text-break'}>
                    <Typeahead
                      id={'assigned_user_search'}
                      labelKey={(option) =>
                        formatName(option.first_name, option.last_name)
                      }
                      minLength={0}
                      allowNew={false}
                      onChange={updateAssignedUser}
                      options={users}
                      placeholder="Assigned User"
                      renderMenuItemChildren={(option, props) =>
                        formatName(option.first_name, option.last_name)
                      }
                      defaultSelected={
                        users
                          ? users.filter((u) => u.id == lead?.assigned_user_id)
                          : []
                      }
                    />
                  </dd>
                </>
              ) : (
                !!lead.assigned_user_id && (
                  <>
                    <dt className={'col-6'}>Assigned User</dt>
                    <dd className={'col-6 text-break'}>{leadAssignedUserName()}</dd>
                  </>
                )
              )}
            </>
          )}

          <dt className={'col-6'}>Value</dt>
          <dd className={'col-6 text-break'}>
            {isEditing ? (
              <CurrencyInput
                className="form-control"
                placeholder={'Value'}
                prefix="$"
                value={opportunityValue}
                onChangeEvent={updateOpportunityValue}
              />
            ) : (
              formattedValue
            )}
          </dd>

          <dt className={'col-6'}>Date Added</dt>
          <dd className={'col-6 text-break'}>
            {moment(lead.created_at).format('LL')}
          </dd>

          <dt className={'col-6'}>Last Contacted</dt>
          <dd className={'col-6 text-break'}>{lastContacted(lead)}</dd>
          {renderCustomFields()}
        </dl>
      </Row>
    </>
  );
};

EditDetails.propTypes = {
  lead: PropTypes.object,
  isEditing: PropTypes.bool,
  user: PropTypes.object,
  setIsSaving: PropTypes.func,
  setIsEditing: PropTypes.func,
  setLead: PropTypes.func,
  setLeads: PropTypes.func,
  onSave: PropTypes.bool,
  setOnSave: PropTypes.func,
  taskSequences: PropTypes.array,
};

export default EditDetails;
