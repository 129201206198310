export const mostRecentOpportunityStatus = (lead) => {
  if (lead.opportunities && lead.opportunities.length == 0) {
    return 'Pending';
  }
  const status = lead.opportunities && lead.opportunities[0].status;
  if (status === 0) return 'Pending';
  if (status === 1) return 'Won';
  if (status === 2) return 'Lost';
  if (!status) return 'Pending';

  return status.charAt(0).toUpperCase() + status.slice(1);
};

export const mostRecentOpportunity = (lead) => {
  if (lead && lead.opportunities) {
    return lead.opportunities.length == 0
      ? '$0'
      : lead.opportunities[0].value_cents / 100;
  } else {
    return '$0';
  }
};

export const stage = (task_sequence) => {
  if (!task_sequence) {
    return;
  }
  if (task_sequence.lead_type == 'warm') {
    return <span className={'sigma-warm'}>{task_sequence.name}</span>;
  }

  return <span className={'sigma-cool'}>{task_sequence.name}</span>;
};

export const mostRecentItem = (list) => {
  return (
    list &&
    list.reduce((x, y) => {
      return x.created_at < y.created_at ? x : y;
    }, false)
  );
};

export const primaryPhone = (lead) => {
  const primary = lead.phones ? lead.phones.filter((x) => x.is_primary) : [];
  if (primary.length == 0) {
    return;
  }
  return primary[0];
};

export const primaryEmail = (lead) => {
  const primary = lead.email_addresses
    ? lead.email_addresses.filter((x) => x.is_primary)
    : [];
  if (primary.length == 0) {
    return;
  }
  return primary[0];
};

export const getPrimaryData = (details) => {
  const primary = details ? details.filter((x) => x.is_primary === true) : [];
  if (primary.length == 0) {
    return { number: '' };
  }
  return primary[0];
};
