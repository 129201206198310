import { useState } from 'react';
import { FilterAccordion, FilterAccordionSummary } from './FilterAccordion';
import {
  AccordionActions,
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

const TODAY = 0,
  YESTERDAY = 1,
  LAST_24_HOURS = 2,
  LAST_BUSINESS_DAY = 3,
  THIS_WEEK = 4,
  PAST_WEEK = 5,
  LAST_7_DAYS = 6,
  THIS_MONTH = 7,
  PAST_MONTH = 8,
  LAST_30_DAYS = 9,
  TOMORROW = 10,
  NEXT_24_HOURS = 11,
  NEXT_WEEK = 12,
  NEXT_7_DAYS = 13,
  NEXT_MONTH = 14,
  NEXT_30_DAYS = 15,
  BETWEEN = 16;

const futureOptions = [10, 11, 12, 13, 14, 15];

const relativeDateOptions = {
  0: 'Today',
  1: 'Yesterday',
  2: 'Last 24 Hours',
  3: 'Last Business Day',
  4: 'This Week',
  5: 'Past Week',
  6: 'Last 7 Days',
  7: 'This Month',
  8: 'Past Month',
  9: 'Last 30 Days',
  10: 'Tomorrow',
  11: 'Next 24 Hours',
  12: 'Next Week',
  13: 'Next 7 Days',
  14: 'Next Month',
  15: 'Next 30 Days',
  16: 'Between',
};

function calculateDateRange(data) {
  const start = new Date();
  const end = new Date();
  const range = Number(data[0].filterValue);
  switch (range) {
    case TODAY:
      start.setHours(0, 0, 0);
      break;
    case YESTERDAY:
      start.setDate(start.getDate() - 1);
      start.setHours(0, 0, 0);
      end.setDate(start.getDate());
      end.setHours(23, 59, 59);
      break;
    case LAST_24_HOURS:
      start.setDate(start.getDate() - 1);
      break;
    case LAST_BUSINESS_DAY:
      if (end.getDay() >= 2 && end.getDay() <= 6) {
        start.setDate(start.getDate() - 1);
      }
      if (end.getDay() == 0) {
        start.setDate(start.getDate() - 2);
      }
      if (end.getDay() == 1) {
        start.setDate(start.getDate() - 3);
      }
      start.setHours(17, 0, 0);
      break;
    case THIS_WEEK:
      start.setDate(start.getDate() - start.getDay());
      start.setHours(0, 0, 0);
      break;
    case PAST_WEEK:
      const first = start.getDate() - 7 - start.getDay();
      start.setDate(first);
      start.setHours(0, 0, 0);
      end.setDate(first + 6);
      end.setHours(23, 59, 59);
      break;
    case LAST_7_DAYS:
      start.setDate(start.getDate() - 7);
      break;
    case THIS_MONTH:
      start.setDate(1);
      start.setHours(0, 0, 0);
      break;
    case PAST_MONTH:
      start.setMonth(start.getMonth() - 1);
      start.setDate(1);
      end.setDate(0);
      start.setHours(0, 0, 0);
      end.setHours(23, 59, 59);
      break;
    case LAST_30_DAYS:
      start.setDate(start.getDate() - 30);
      break;
    case TOMORROW:
      start.setDate(start.getDate() + 1);
      start.setHours(0, 0, 0);
      end.setDate(start.getDate());
      end.setHours(23, 59, 59);
      break;
    case NEXT_24_HOURS:
      end.setDate(end.getDate() + 1);
      break;
    case NEXT_WEEK:
      const nextW = start.getDate() + ((1 + 7 - start.getDay()) % 7 || 7);
      start.setDate(nextW);
      start.setHours(0, 0, 0);
      end.setDate(nextW + 6);
      end.setHours(23, 59, 59);
      break;
    case NEXT_7_DAYS:
      end.setDate(start.getDate() + 7);
      break;
    case NEXT_MONTH:
      start.setMonth(start.getMonth() + 1);
      start.setDate(1);
      end.setMonth(start.getMonth() + 1);
      end.setDate(0);
      start.setHours(0, 0, 0);
      end.setHours(23, 59, 59);
      break;
    case NEXT_30_DAYS:
      end.setDate(end.getDate() + 30);
      break;
    case BETWEEN:
      if (data[0].startDate) {
        start.setFullYear(...data[0].startDate.substring(0, 10).split('-'));
        start.setMonth(start.getMonth() - 1);
        start.setHours(0, 0, 0);
      }
      if (data[0].endDate) {
        end.setFullYear(...data[0].endDate.substring(0, 10).split('-'));
        end.setMonth(end.getMonth() - 1);
        end.setHours(23, 59, 59);
      }
      if (
        (data[0].endDate && !data[0].startDate) ||
        data[0].endDate < data[0].startDate
      ) {
        start.setFullYear(2021, 11, 10);
        start.setHours(0, 0, 0);
      }

      break;
  }
  return [start, end];
}

function formatQueryParams(fieldName, selector, start, end) {
  return [
    [`${fieldName}_selector`, selector],
    [`${fieldName}_start`, start.toISOString()],
    [`${fieldName}_end`, end.toISOString()],
  ];
}

export function deserializeSavedDateFilter(filters) {
  const map = {};

  filters.forEach((filter) => {
    const filterMapKey = filter[0];
    if (
      [
        'created_at_selector',
        'lead_created_at_selector',
        'last_contacted_at_selector',
        'next_task_due_at_selector',
      ].includes(filterMapKey)
    ) {
      map['filterValue'] = filter[1];
      map['name'] = relativeDateOptions[filter[1]];
    }
    if (
      [
        'created_at_start',
        'lead_created_at_start',
        'last_contacted_at_start',
        'next_task_due_at_start',
      ].includes(filterMapKey)
    ) {
      map['startDate'] = filter[1];
    }
    if (
      [
        'created_at_end',
        'lead_created_at_end',
        'last_contacted_at_end',
        'next_task_due_at_end',
      ].includes(filterMapKey)
    ) {
      map['endDate'] = filter[1];
    }
  });

  return [map];
}

export function convertDateFilterToQueryParams(fieldName, k, v) {
  const selector = Number(v[0].filterValue);
  const [start, end] = calculateDateRange(v);
  return formatQueryParams(fieldName, selector, start, end);
}

export function DateFilter({
  setSelectedSavedSearchId,
  setFilterMapValue,
  filterMap,
  fieldName,
  title = 'Lead Created',
  displayFuture = false,
}) {
  const [minDateAvailable, setMinDateAvailable] = useState();
  const [maxDateAvailable, setMaxDateAvailable] = useState();

  const renderOptions = Object.keys(relativeDateOptions).filter((key) => {
    return displayFuture
      ? futureOptions.includes(parseInt(key))
      : !futureOptions.includes(parseInt(key));
  });

  return (
    <FilterAccordion>
      <FilterAccordionSummary>
        {title}
        {filterMap[fieldName].length > 0 && (
          <Chip
            onDelete={() => {
              setSelectedSavedSearchId(null);
              setFilterMapValue(fieldName, []);
              setMinDateAvailable();
              setMaxDateAvailable();
            }}
            label={filterMap[fieldName].length}
          />
        )}
      </FilterAccordionSummary>
      <AccordionActions>
        <FormControl fullWidth size="small">
          <Box display="flex" flexDirection="column" rowGap={1} width="100%">
            <InputLabel id="date-filter-label">Since</InputLabel>
            <Select
              fullWidth
              label="Since"
              labelId="date-filter-label"
              size="small"
              value={filterMap[fieldName][0]?.filterValue ?? ''}
              onChange={(event) => {
                setSelectedSavedSearchId(null);
                setFilterMapValue(fieldName, [{ filterValue: event.target.value }]);
                setMinDateAvailable();
                setMaxDateAvailable();
              }}
            >
              {renderOptions.map((key, _) => (
                <MenuItem key={key} value={key}>
                  {relativeDateOptions[key]}
                </MenuItem>
              ))}
            </Select>
            {parseInt(filterMap[fieldName][0]?.filterValue) === 16 && (
              <>
                <TextField
                  InputProps={{ sx: { fontSize: '14px' } }}
                  sx={{ '& legend': { display: 'none' } }}
                  fullWidth
                  type="date"
                  inputProps={{ max: maxDateAvailable }}
                  label=""
                  InputLabelProps={{ shrink: true }}
                  value={filterMap[fieldName][0]?.startDate?.substring(0, 10)}
                  onChange={(event) => {
                    setSelectedSavedSearchId(null);
                    setFilterMapValue(fieldName, [
                      {
                        ...filterMap[fieldName][0],
                        startDate: event.target.value,
                      },
                    ]);
                    setMinDateAvailable(event.target.value);
                  }}
                />
                <TextField
                  InputProps={{ sx: { fontSize: '14px' } }}
                  sx={{ '& legend': { display: 'none' } }}
                  fullWidth
                  type="date"
                  inputProps={{ min: minDateAvailable }}
                  label=""
                  InputLabelProps={{ shrink: true }}
                  value={filterMap[fieldName][0]?.endDate?.substring(0, 10)}
                  onChange={(event) => {
                    setSelectedSavedSearchId(null);
                    setFilterMapValue(fieldName, [
                      {
                        ...filterMap[fieldName][0],
                        endDate: event.target.value,
                      },
                    ]);
                    setMaxDateAvailable(event.target.value);
                  }}
                />
              </>
            )}
          </Box>
        </FormControl>
      </AccordionActions>
    </FilterAccordion>
  );
}
