import Cookies from 'js-cookie';
import { toJSON } from '../queryClientConfig';

export default class Cloud9Calendar {
  static async create(filters) {
    return await fetch(
      `${process.env.REACT_APP_CLOUD9_CALENDAR}/v1/cloud9/calendars`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(filters),
      }
    ).then(toJSON);
  }

  static async edit(filters) {
    return await fetch(
      `${process.env.REACT_APP_CLOUD9_CALENDAR}/v1/cloud9/calendars/${calendar_id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(filters),
      }
    ).then(toJSON);
  }
}
