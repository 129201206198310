import { useState } from 'react';
import { colorValues } from '../../../util/Constants';
import { actionId } from '../../../util/AppUtils';
import ClipLoader from 'react-spinners/ClipLoader';
import { getTaskActionNameString } from '../../../components/tasks/TaskUtils';
import { tasksQueryKeys, useTasksList } from '../../services/tasks/queries';
import { useQueryClient } from 'react-query';
import { useTasksCreate, useTasksEdit } from '../../services/tasks/mutations';
import { useUserOrganizationConnectionsList } from '../../services/userOrganizationConnections/queries';
import {
  Box,
  Typography,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  IconButton,
  Menu,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  EmailIcon,
  ExclamationIcon,
  PhoneIcon,
  TextIcon,
  TaskCanceledIcon,
  TaskOpenIcon,
  TaskFuturueIcon,
  TaskCompletedIcon,
  DotMenuIcon,
} from '../../theme/icons';
import { useUsersCurrent, useUsersList } from '../../services/users/queries';
import { formatName } from '../../services/textFormatting';
import { EventCard } from './EventCard';
import { useMessageTemplatesList } from '../../services/messageTemplates/queries';
import { messageTemplateContentToPlainText } from '../../services/messageTemplates/messageTemplatesUtils';
import {
  dateToAbbreviatedDateString,
  dateToAbbreviatedDateTimeString,
  formatIsoStringToDateUTC,
} from '../../services/dateTime';
import { fireDialog } from '../Dialog';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

const TaskEventHistoryTab = ({ lead }) => {
  const queryClient = useQueryClient();
  const [futureTaskActionName, setFutureTaskActionName] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const { mutate: createTask, isLoading: isCreatingTask } = useTasksCreate();

  // If due_at is null, add the numbers of days_after to today's date
  const calculateDueAt = (task) => {
    if (!task) return null;
    if (task.due_at) return task.due_at;
    const daysAfter = task.days_after;
    const dueAt = moment().add(daysAfter, 'days').format();
    return dueAt;
  };

  const filterSortTasks = (tasks) => {
    const sorted = tasks.items.sort((a, b) => {
      const a2 = a.completed_at
        ? moment.utc(a.completed_at)
        : moment.utc(calculateDueAt(a));
      const b2 = b.completed_at
        ? moment.utc(b.completed_at)
        : moment.utc(calculateDueAt(b));
      return a2.diff(b2);
    });
    return sorted;
  };

  const { data: messageTemplatesList } = useMessageTemplatesList();
  const {
    data: userConnectionsList,
    isLoading: isLoadingUserConnectionsList,
  } = useUserOrganizationConnectionsList();
  const { data: usersList, isLoading: isLoadingUsersList } = useUsersList();
  const { data: tasksList, isLoading: isLoadingTasks } = useTasksList(
    [
      ['lead_id', lead?.id],
      ['page_size', 1000],
      ['canceled', false],
    ],
    {
      select: filterSortTasks,
      enabled: !!lead?.id,
    }
  );

  const getTaskUsername = (task) => {
    if (!task.user_id && task.completed_at) return 'LeadSigma';

    const foundConnection = userConnectionsList?.items.find(
      ({ user_id }) => user_id === task.user_id
    );
    const foundUser = usersList?.find(({ id }) => id === task.user_id);
    if (task.user_id) {
      if (foundConnection && foundUser) {
        return formatName(foundUser.first_name, foundUser.last_name);
      }
    }
    if (task.user_id && !isLoadingUserConnectionsList && !isLoadingUsersList) {
      return 'Enterprise User';
    }
    // skeleton while loading?
    return 'Uncompleted';
  };

  const createFutureTask = () => {
    createTask(
      {
        lead_id: lead.id,
        task_action_id: actionId[futureTaskActionName],
        due_at: selectedDate,
        organization_id: lead.organization_id,
      },
      {
        onSuccess: () => {
          setSelectedDate('');
          setFutureTaskActionName('');
          queryClient
            .invalidateQueries(tasksQueryKeys.list([['lead_id', lead.id]]))
            .then(() =>
              queryClient.refetchQueries(tasksQueryKeys.list([['lead_id', lead.id]]))
            );
        },
      }
    );
  };

  const taskEvent = (task) => {
    const isTaskCompleted = !!task.completed_at;
    const isTaskCanceled = !!task.canceled_at;
    const thisMorning = moment().utc().startOf('day');
    const taskDueAtMorning = moment(calculateDueAt(task)).utc().startOf('day');
    const isTaskOpen = !task.closed_at && taskDueAtMorning <= thisMorning;
    const isFutureTask = !task.closed_at && !isTaskOpen;

    const foundTemplate = messageTemplatesList?.find(
      ({ id }) => id === task.message_template_id
    );

    const content =
      task.message_template_id &&
      task.automated &&
      messageTemplateContentToPlainText(foundTemplate?.content);

    const getTimestamp = () => {
      if (isTaskCompleted) return dateToAbbreviatedDateTimeString(task.completed_at);
      return dateToAbbreviatedDateString(calculateDueAt(task));
    };

    const getTimestampIcon = () => {
      if (isTaskCanceled) return <TaskCanceledIcon color="error" />;
      if (isTaskCompleted) return <TaskCompletedIcon color="primary" />;
      if (isTaskOpen) return <TaskOpenIcon />;
      if (isFutureTask) return <TaskFuturueIcon />;
    };

    const getMetaTexts = () => {
      if (!isTaskOpen && !isFutureTask) return [`User: ${getTaskUsername(task)}`];
      return null;
    };

    return (
      <EventCard
        key={task.id}
        icon={communicationIconMap[task.task_action.name]}
        renderTitle={() =>
          isTaskOpen ? (
            <Link
              sx={{ color: ({ palette }) => palette.info.main }}
              component={NavLink}
              to={`/tasks/${task.id}`}
              underline="none"
            >
              {getTaskActionNameString(task.task_action.name)}
            </Link>
          ) : (
            <Typography>{getTaskActionNameString(task.task_action.name)}</Typography>
          )
        }
        timestamp={getTimestamp()}
        timestampIcon={getTimestampIcon()}
        actionButton={
          (isFutureTask || isTaskOpen) && (
            <CancelTaskDropdown
              onClick={() =>
                fireDialog((promiseProps) =>
                  CancelTaskDialog({ ...promiseProps, task })
                )
              }
            />
          )
        }
        metaTexts={task.message_template_id && task.automated && getMetaTexts()}
        collapseContent={content}
      />
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      px={2}
      height="100%"
      overflow="auto"
    >
      {isLoadingTasks || isCreatingTask ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <ClipLoader size={300} color={colorValues.primaryColor} />
        </Box>
      ) : (
        <>
          <Box display="flex" flexDirection="column" gap={1}>
            {tasksList?.map((task) => taskEvent(task))}
          </Box>
          <Divider />
          <Box display="flex" flexWrap="wrap" gap={2}>
            <Typography sx={{ flexBasis: '100%' }} fontWeight={500}>
              Create Future Task
            </Typography>
            <Box display="flex" flex="100%" gap={2}>
              <FormControl size="small" sx={{ flex: 1 }} fullWidth>
                <InputLabel id="task-action">Task Action</InputLabel>
                <Select
                  value={futureTaskActionName}
                  onChange={(e) => {
                    setFutureTaskActionName(e.target.value.replace(/\s/g, ''));
                  }}
                  label="Task Action"
                >
                  {['Call', 'Email', 'Text', 'FollowUp', 'ReEngage'].map(
                    (taskAction) => (
                      <MenuItem key={taskAction} value={taskAction}>
                        {getTaskActionNameString(taskAction)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <TextField
                sx={{ flex: 1 }}
                value={formatIsoStringToDateUTC(selectedDate)}
                onChange={(e) => {
                  var d = new Date();
                  var time = d.toLocaleTimeString();
                  if (e.target.value === '') setSelectedDate('');
                  else
                    setSelectedDate(
                      new Date(`${e.target.value} ${time}`).toISOString()
                    );
                }}
                inputProps={{
                  min: formatIsoStringToDateUTC(moment()),
                }}
                label="Date"
                InputLabelProps={{ shrink: true }}
                type="date"
              />
            </Box>
            <LoadingButton
              loading={isCreatingTask}
              disabled={!selectedDate || !futureTaskActionName}
              onClick={() => createFutureTask()}
            >
              Create
            </LoadingButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default TaskEventHistoryTab;

//----------------------------------------------------------------------
const CancelTaskDialog = ({ isOpen, onResolve, onReject, task }) => {
  const { data: currentUser } = useUsersCurrent();
  const { mutate: editTask, isLoading } = useTasksEdit();

  const handleCancelTask = () => {
    editTask(
      {
        id: task.id,
        user_id: currentUser.id,
        canceled_at: new Date().toISOString(),
      },
      {
        onSuccess: () => onResolve(),
      }
    );
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Cancel Task</DialogTitle>
      <DialogContent>Are you sure you would like to cancel this task?</DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Close
        </Button>
        <LoadingButton loading={isLoading} onClick={handleCancelTask}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
//----------------------------------------------------------------------
const communicationIconMap = {
  Call: <PhoneIcon />,
  Email: <EmailIcon />,
  Text: <TextIcon />,
  FollowUp: <ExclamationIcon color="secondary" />,
  ReEngage: <ExclamationIcon color="secondary" />,
};
//----------------------------------------------------------------------
const CancelTaskDropdown = ({ onClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;
  return (
    <>
      <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <DotMenuIcon />
      </IconButton>
      <Menu open={open} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            onClick();
            setAnchorEl(null);
          }}
        >
          Cancel
        </MenuItem>
      </Menu>
    </>
  );
};
