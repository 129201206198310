import { v4 as uuid } from 'uuid';
import {
  Greeting,
  Dial,
  VoiceMail,
  InitialList,
  Menu,
  CreateLead,
  Hangup,
  Ai,
  Play,
} from '../index';

export const availableOptions = {
  title: 'What would you like to happen ?',
  isListOptions: true,
  id: uuid(),
  component: <InitialList />,
  action: 'initialList',
};

export const menuOption = {
  title: 'Menu',
  isListOptions: false,
  activeCallerPressOption: 1,
  availableCallerPressOption: {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
  },
  id: uuid(),
  component: <Menu />,
  action: 'respond',
};

export const greetingOption = {
  title: 'Greeting',
  parameters: {},
  isListOptions: false,
  id: uuid(),
  component: <Greeting />,
  action: 'say',
};

export const playOption = {
  title: 'Play',
  parameters: {},
  isListOptions: false,
  id: uuid(),
  component: <Play />,
  action: 'play',
};

export const aiOption = {
  title: 'AI',
  parameters: {},
  isListOptions: false,
  id: uuid(),
  component: <Ai />,
  action: 'ai',
};

export const dialOption = {
  title: 'Dial',
  parameters: {},
  isListOptions: false,
  id: uuid(),
  component: <Dial />,
  action: 'forward',
};

export const createLeadOption = {
  title: 'Create Lead',
  parameters: {},
  isListOptions: false,
  id: uuid(),
  component: <CreateLead />,
  action: 'create_lead',
};

export const voiceMailOption = {
  action: 'record',
  parameters: {},
  title: 'VoiceMail',
  isListOptions: false,
  id: uuid(),
  component: <VoiceMail />,
};

export const hangupOption = {
  action: 'hangup',
  parameters: {},
  title: 'Hangup',
  isListOptions: false,
  id: uuid(),
  component: <Hangup />,
};
