import { useState } from 'react';
import { MenuItem, TextareaAutosize, TextField } from '@mui/material';

const options = [
  { operator: 'Polly.Ivy', label: 'Polly.Ivy' },
  { operator: 'Polly.Joanna', label: 'Polly.Joanna' },
  { operator: 'Polly.Joey', label: 'Polly.Joey' },
  { operator: 'Polly.Justin', label: 'Polly.Justin' },
  { operator: 'Polly.Kendra', label: 'Polly.Kendra' },
  { operator: 'Polly.Kimberly', label: 'Polly.Kimberly' },
  { operator: 'Polly.Matthew', label: 'Polly.Matthew' },
  { operator: 'Polly.Salli', label: 'Polly.Salli' },
  { operator: 'Polly.Neural', label: 'Polly.Neural' },
  { operator: 'Polly.Kevin-Neural', label: 'Polly.Kevin - Neural' },
  { operator: 'Polly.Kimberly-Neural', label: 'Polly.Kimberly - Neural' },
  { operator: 'Polly.Joey-Neural', label: 'Polly.Joey - Neural' },
  { operator: 'Polly.Justin-Neural', label: 'Polly.Justin - Neural' },
  { operator: 'Polly.Stephen-Neural', label: 'Polly.Stephen - Neural' },
  { operator: 'Google.en-US-Standard-A', label: 'Google.en-US-Standard-A' },
  { operator: 'Google.en-US-Standard-B', label: 'Google.en-US-Standard-B' },
  { operator: 'Google.en-US-Standard-C', label: 'Google.en-US-Standard-C' },
  { operator: 'Google.en-US-Standard-D', label: 'Google.en-US-Standard-D' },
  { operator: 'Google.en-US-Standard-E', label: 'Google.en-US-Standard-E' },
  { operator: 'Google.en-US-Standard-F', label: 'Google.en-US-Standard-F' },
  { operator: 'Google.en-US-Standard-G', label: 'Google.en-US-Standard-G' },
  { operator: 'Google.en-US-Standard-H', label: 'Google.en-US-Standard-H' },
  { operator: 'Google.en-US-Standard-I', label: 'Google.en-US-Standard-I' },
  { operator: 'Google.en-US-Standard-J', label: 'Google.en-US-Standard-J' },
  { operator: 'Google.en-US-Wavenet-A', label: 'Google.en-US-Wavenet-A' },
  { operator: 'Google.en-US-Wavenet-B', label: 'Google.en-US-Wavenet-B' },
  { operator: 'Google.en-US-Wavenet-C', label: 'Google.en-US-Wavenet-C' },
  { operator: 'Google.en-US-Wavenet-D', label: 'Google.en-US-Wavenet-D' },
  { operator: 'Google.en-US-Wavenet-E', label: 'Google.en-US-Wavenet-E' },
  { operator: 'Google.en-US-Wavenet-F', label: 'Google.en-US-Wavenet-F' },
  { operator: 'Google.en-US-Wavenet-G', label: 'Google.en-US-Wavenet-G' },
  { operator: 'Google.en-US-Wavenet-H', label: 'Google.en-US-Wavenet-H' },
  { operator: 'Google.en-US-Wavenet-I', label: 'Google.en-US-Wavenet-I' },
  { operator: 'Google.en-US-Wavenet-J', label: 'Google.en-US-Wavenet-J' },
  { operator: 'Google.en-US-Neural2-A', label: 'Google.en-US-Neural2-A' },
  { operator: 'Google.en-US-Neural2-B', label: 'Google.en-US-Neural2-B' },
  { operator: 'Google.en-US-Neural2-C', label: 'Google.en-US-Neural2-C' },
  { operator: 'Google.en-US-Neural2-D', label: 'Google.en-US-Neural2-D' },
  { operator: 'Google.en-US-Neural2-E', label: 'Google.en-US-Neural2-E' },
  { operator: 'Google.en-US-Neural2-F', label: 'Google.en-US-Neural2-F' },
  { operator: 'Google.en-US-Neural2-G', label: 'Google.en-US-Neural2-G' },
  { operator: 'Google.en-US-Neural2-H', label: 'Google.en-US-Neural2-H' },
  { operator: 'Google.en-US-Neural2-I', label: 'Google.en-US-Neural2-I' },
  { operator: 'Google.en-US-Neural2-J', label: 'Google.en-US-Neural2-J' },
];

export const Greeting = ({
  handleMutateComponentListByParametersInUI,
  id,
  parameters,
  doSearchEmptyInputs,
  getUpdatedInputParams,
}) => {
  const [inputParameters, setInputParameters] = useState(
    getUpdatedInputParams(id)?.parameters || parameters
  );

  const handleChange = (e) => {
    setInputParameters({
      ...inputParameters,
      [e.target.name]: e.target.value,
    });
    handleMutateComponentListByParametersInUI(
      { ...inputParameters, [e.target.name]: e.target.value },
      id
    );
  };

  return (
    <>
      <TextField
        sx={{ width: '300px', marginBottom: '20px' }}
        value={inputParameters.voice}
        label="Please choose voice"
        select
        name="voice"
        onChange={handleChange}
        error={doSearchEmptyInputs && !inputParameters.voice}
      >
        {options.map(({ operator, label }) => {
          return (
            <MenuItem key={operator} value={operator}>
              {label}
            </MenuItem>
          );
        })}
      </TextField>
      <br />
      <TextField
        label="Message"
        placeholder="Type your message here"
        style={{ width: '300px' }}
        value={inputParameters.message}
        name="message"
        onChange={handleChange}
        multiline
        error={doSearchEmptyInputs && !inputParameters.message}
      />
    </>
  );
};
