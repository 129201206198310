import moment from 'moment-timezone';

export const dateToPrettyDateTimeString = (date) =>
  date ? moment(date).format('LLL') : null;

export const dateToLongPrettyDateTimeString = (date) => {
  if (date) return moment(date).format('');
  return 'none';
};

export const toUtcTimestamp = (date) =>
  date ? moment(date).utc().format('hh:mm A') : '';

export const toSimpleTime = (time) => {
  time ? time.format('HH:mm') : '';
};

export const dateToPrettyDateString = (date) => {
  return moment(date).format('ddd, MMM D, YYYY');
};

export const formatIsoStringToDateTimeLocal = (isoString) => {
  if (!isoString) return '';
  return moment(isoString).local().format('YYYY-MM-DDTHH:mm');
};

export const formatIsoStringToDateUTC = (isoString) => {
  if (!isoString) return '';
  return moment(isoString).utc().format('YYYY-MM-DD');
};

export const dateToAbbreviatedDateString = (date) => {
  return moment(date).utc().format('ddd, MMM D, YYYY');
};

export const dateToAbbreviatedDateTimeString = (date) => {
  return moment(date).format('ddd, MMM D, YYYY  h:mma');
};

export const dateToAbbreviatedDateTimeNoWeekdayString = (date) => {
  return moment(date).format('MMM D, YYYY  h:mma');
};

export const dateToTimeWithTimezoneString = (date) => {
  return moment(date).tz(moment.tz.guess()).format('h:mma z');
};

export const convertIsoToLocalDateTimeString = (isoString) => {
  return moment(isoString).local().format('YYYY-MM-DDTHH:mm:ss');
};
