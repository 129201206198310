import { toJSON } from '../queryClientConfig';
import Cookies from 'js-cookie';

export default class Enterprises {
  static async list(organization_id) {
    return await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/organization_service/enterprises?organization_id=${organization_id}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async show(id) {
    return await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/organization_service/enterprises/${id}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async edit(enterprise) {
    return await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/organization_service/enterprises/${enterprise.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(enterprise),
      }
    ).then(toJSON);
  }
}
