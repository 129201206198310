import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { FieldOption } from './FieldOption';
import { useSizeUiTracker } from '../../hooks';

const selectOptions = [
  { operator: '1', label: ' 1' },
  { operator: '2', label: ' 2' },
  { operator: '3', label: ' 3' },
  { operator: '4', label: ' 4' },
  { operator: '5', label: ' 5' },
  { operator: '6', label: ' 6' },
  { operator: '7', label: ' 7' },
  { operator: '8', label: ' 8' },
  { operator: '9', label: ' 9' },
];

export const Menu = ({
  id,
  activeCallerPressOption,
  handleDeleteBranchInBuilder,
  handleMutateBuilderCallerPressOption,
  availableCallerPressOption,
  handleAddComponent,
  ...rest
}) => {
  const wrapper = (itemSelectValue, isActive) => {
    return (
      <FieldOption
        mutate={mutate}
        itemSelectValue={itemSelectValue}
        isActive={isActive}
        id={id}
        takenOptionIndexes={takenOptionIndexes}
        setTakenOptionsIndexes={setTakenOptionsIndexes}
        handleRemoveOption={handleRemoveOption}
        selectOptions={selectOptions}
        defineActiveOption={defineActiveOption}
        activeCallerPressOption={activeCallerPressOption}
        handleMutateBuilderCallerPressOption={handleMutateBuilderCallerPressOption}
      />
    );
  };

  const [takenOptionIndexes, setTakenOptionsIndexes] = useState(new Set());
  const [callerPressList, setCallerPressList] = useState([]);
  const { top, height } = useSizeUiTracker(selectOptions, callerPressList);

  const mutate = (prevVal, newVal) => {
    setCallerPressList((list) => {
      return list.map((el) => {
        const val =
          el.props.itemSelectValue === +prevVal ? newVal : el.props.itemSelectValue;
        return wrapper(val, el.props.isActive);
      });
    });
  };

  const generateFirstLowValue = () => {
    for (let i = 1; i <= selectOptions.length; i++) {
      if (!takenOptionIndexes.has(i)) {
        return i;
      }
    }
  };

  function handleAddOption() {
    const val = generateFirstLowValue();
    setTakenOptionsIndexes((list) => {
      list.add(+val);
      return list;
    });
    setCallerPressList((prevList) => {
      return prevList
        .map((el) => {
          return wrapper(el.props.itemSelectValue, el.props.isActive);
        })
        .concat(wrapper(val, !prevList.length));
    });
  }

  function handleRemoveOption(selectValue) {
    setTakenOptionsIndexes((list) => {
      list.delete(+selectValue);
      return list;
    });
    setCallerPressList((prevList) =>
      prevList
        .filter((item) => item.props.itemSelectValue !== selectValue)
        .map((el) => {
          return wrapper(el.props.itemSelectValue, el.props.isActive);
        })
    );
    handleDeleteBranchInBuilder(id, selectValue);
  }

  function defineActiveOption(e, val) {
    const clickOnParent = e.target.dataset.parent && e.currentTarget.dataset.parent;
    if (clickOnParent) {
      setCallerPressList((prevList) => {
        return prevList.map((item) => {
          return wrapper(
            item.props.itemSelectValue,
            item.props.itemSelectValue === val
          );
        });
      });
      handleMutateBuilderCallerPressOption(+val, id);
    }
  }

  useEffect(() => {
    let totalValues = [];

    for (let key in availableCallerPressOption) {
      const branch = availableCallerPressOption[key].filter(
        (el) => el.action !== 'initialList'
      );
      if (
        branch.length ||
        branch[0]?.action === 'respond' ||
        branch[0]?.action === 'ai' ||
        branch[0]?.action === 'hangup'
      ) {
        totalValues.push(key);
      }
    }

    if (totalValues.length) {
      const data = totalValues.map((el) => {
        setTakenOptionsIndexes((list) => {
          list.add(+el);
          return list;
        });
        return wrapper(el, el === totalValues[0]);
      });
      const isActiveBranch = data.filter((el) => el.props.isActive)[0];
      handleMutateBuilderCallerPressOption(
        +isActiveBranch.props.itemSelectValue,
        id
      );
      setCallerPressList(data);
    } else {
      setTakenOptionsIndexes((list) => {
        list.add(+activeCallerPressOption);
        return list;
      });
      setCallerPressList((list) =>
        list.concat(wrapper(activeCallerPressOption, true))
      );
      handleMutateBuilderCallerPressOption(+activeCallerPressOption, id);
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          position: `${!callerPressList.length ? 'static' : 'relative'}`,
          zIndex: '2',
          '&::before': {
            content: '""',
            position: 'absolute',
            height,
            zIndex: '-1',
            width: '5%',
            backgroundColor: 'transparent',
            top,
            right: '20px',
            border: '2px solid black',
            borderBottomColor: 'transparent',
            borderLeftColor: 'transparent',
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            height: '31px',
            zIndex: '-1',
            width: 'calc(50% - 20px)',
            backgroundColor: 'transparent',
            bottom: '-29px',
            right: '20px',
            border: '2px solid black',
            borderRightColor: 'transparent',
            borderBottomColor: 'transparent',
          },
        }}
      >
        {callerPressList.map((item) => {
          return <Box key={item.props.itemSelectValue}>{item}</Box>;
        })}
        {selectOptions.length === callerPressList.length || (
          <Button onClick={handleAddOption} sx={{ mt: 2 }}>
            + Add menu option
          </Button>
        )}
      </Box>

      {callerPressList.length === 0 || (
        <Box
          sx={{
            width: '100%',
            position: 'absolute',
            bottom: '-50px',
            textAlign: 'center',
          }}
        >
          The caller pressed "{activeCallerPressOption}"
        </Box>
      )}
    </>
  );
};
